/* tslint:disable */
export enum GlobalPermissions {
  G_Performance_Menu = 49,
  G_Events_Menu = 1,
  G_Events_ShowOnlyWhereAppliedCountMoreThanZero = 2,
  G_Events_ShowFunctionButtons = 3,
  G_Events_ShowStatisticsOnEventCard = 4,
  G_Events_ShowAllEventsRegardlessLabels = 5,
  G_Events_PausedEventsVisible = 6,
  G_Events_ShowPauseButton = 7,
  G_Events_OrganizerShowAllEventsRegardlessLabels = 8,
  G_Events_ShowEventSettingsButton = 9,
  G_Events_ShowReportButton = 10,

  G_EventSettings_Edit = 12,
  G_EventSettings_ViewUserData = 13,
  G_EventSettings_EventUserStateChange = 14,
  G_EventSettings_ChangeUsersRole = 15,
  G_EventSettings_UsersOneDayOverloadEnabled = 16,

  G_Presence_Menu = 17,
  G_Presence_Show_Expand = 59,
  G_Presence_Export2 = 18,
  G_Presence_Export1 = 19,

  G_Statistics_Menu = 20,
  G_HrStatistics_Menu = 25,
  G_HrStatistics_Export = 26,
  G_PersonalStatistics_Menu = 48,
  G_PersonalStatistics_Import = 21,
  G_PersonalStatistics_Export = 22,
  G_PersonalStatistics_Delete = 23,
  G_PeriodicStatistics_Menu = 50,
  G_PeriodicStatistics_Export = 51,

  G_Payments_Menu = 53,
  G_Payments_UpdateAndDeleteOlderPayments = 54,

  G_Users_Menu = 27,
  G_Users_Delete = 28,
  G_Users_BulkAssignLabels = 29,
  G_Users_DetailedSearchFields = 30,
  G_Users_Import = 31,
  G_Users_Export = 32,

  G_UsersPersonalData_EditHighlightedData = 33,
  G_UsersPersonalData_UseAllEmployersAsContact = 34,
  G_UsersPersonalData_EditEmploymentData = 35,
  G_UsersPersonalData_CanChooseOtherEmployerRelations = 36,
  G_UsersPersonalData_EditRecommendationField = 37,
  G_UsersPersonalData_EditUserIds = 38,
  G_UsersPersonalData_AssignOtherLabelsToUsers = 39,

  G_Settings_Menu = 40,

  G_Permissions_Menu = 41,

  G_Contact_Menu = 43,

  G_Notifications_CreateNotification = 44,
  G_Notifications_Delete = 45,
  G_SentNotifications_Menu = 52,

  G_Other_CompanyUnit_RestrictedByOnlyAssignedLabels = 46,
  G_Other_ShowUsersFromOtherEmployers = 47,

  G_Log_Menu = 58,
  G_Log_Export = 49,
}
