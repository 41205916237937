/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CheckUniquenessQuery } from '../models/check-uniqueness-query';
import { CheckUserInactivabilityQuery } from '../models/check-user-inactivability-query';
import { CheckUserInactivabilityResult } from '../models/check-user-inactivability-result';
import { CheckUserLabelRemovabilityQuery } from '../models/check-user-label-removability-query';
import { CreateOrUpdateUserCardsCommand } from '../models/create-or-update-user-cards-command';
import { DeleteUsersModel } from '../models/delete-users-model';
import { DrivingLicenseData } from '../models/driving-license-data';
import { EmployerData } from '../models/employer-data';
import { EmployerRelationshipData } from '../models/employer-relationship-data';
import { ExportUserDataQuery } from '../models/export-user-data-query';
import { ExportUsersQuery } from '../models/export-users-query';
import { FileStream } from '../models/file-stream';
import { ImportUserDataCommand } from '../models/import-user-data-command';
import { ImportedUserDataLineResult } from '../models/imported-user-data-line-result';
import { LabelData } from '../models/label-data';
import { ListUserCardsQuery } from '../models/list-user-cards-query';
import { ListUserCardsResult } from '../models/list-user-cards-result';
import { ListUserSelectsResult } from '../models/list-user-selects-result';
import { ListUsersQuery } from '../models/list-users-query';
import { ListUsersResult } from '../models/list-users-result';
import { ModifyUserLabelCommand } from '../models/modify-user-label-command';
import { PictureType } from '../models/picture-type';
import { ReactivateUserCommand } from '../models/reactivate-user-command';
import { RoleData } from '../models/role-data';
import { SchoolTypeData } from '../models/school-type-data';
import { SelectAllUsersResult } from '../models/select-all-users-result';
import { SendRegistrationLinkCommand } from '../models/send-registration-link-command';
import { ShiftData } from '../models/shift-data';
import { UserCardData } from '../models/user-card-data';
import { UserData } from '../models/user-data';
import { UserDataBase } from '../models/user-data-base';
import { UserState } from '../models/user-state';
import { UserStateData } from '../models/user-state-data';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userGet
   */
  static readonly UserGetPath = '/api/User';

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Plain$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    UserEmail?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserData>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('UserEmail', params.UserEmail, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserData>;
      })
    );
  }

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Plain(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    UserEmail?: string;
  },
  context?: HttpContext

): Observable<UserData> {

    return this.userGet$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    UserEmail?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserData>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('UserEmail', params.UserEmail, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserData>;
      })
    );
  }

  /**
   * Felhasználó adatainak lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGet(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
    RequestUserId?: number;
    UserEmail?: string;
  },
  context?: HttpContext

): Observable<UserData> {

    return this.userGet$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * Path part for operation userList
   */
  static readonly UserListPath = '/api/User';

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userList$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userList$Plain$Response(params?: {
    body?: ListUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListUsersResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListUsersResult>;
      })
    );
  }

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userList$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userList$Plain(params?: {
    body?: ListUsersQuery
  },
  context?: HttpContext

): Observable<ListUsersResult> {

    return this.userList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListUsersResult>) => r.body as ListUsersResult)
    );
  }

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userList()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userList$Response(params?: {
    body?: ListUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListUsersResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListUsersResult>;
      })
    );
  }

  /**
   * Felhasználók listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userList$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userList(params?: {
    body?: ListUsersQuery
  },
  context?: HttpContext

): Observable<ListUsersResult> {

    return this.userList$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListUsersResult>) => r.body as ListUsersResult)
    );
  }

  /**
   * Path part for operation userPost
   */
  static readonly UserPostPath = '/api/User/Post';

  /**
   * Felhasználó létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  userPost$Response(params?: {
    body?: {
'Id'?: number;
'UserCards'?: Array<UserCardData>;
'ProfilePicture'?: Blob;
'ProfilePictureRotationDegree'?: number;
'LungScreeningPicture'?: Blob;
'HealthRecordPicture'?: Blob;
'RequestUserId'?: number;
'SetAdmissioned'?: boolean;
'KeepFutureEvents'?: boolean;
'TimeZoneId'?: string;
'State'?: UserState;
'Password'?: string;
'Permissions.GlobalPermissions'?: string;
'RoleName'?: string;
'LocalizedRoleName'?: string;
'Role.Id'?: number;
'Role.Permissions.GlobalPermissions'?: string;
'Role.RoleName'?: string;
'Role.Description'?: string;
'Role.Order'?: number;
'Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.Nationality'?: string;
'BaseData.IDType.Id'?: number;
'BaseData.IDType.Name'?: string;
'BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.IDNumber'?: string;
'BaseData.Employer.Id'?: number;
'BaseData.Employer.Name'?: string;
'BaseData.Employer.Active'?: boolean;
'BaseData.Employer.Email'?: string;
'BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.EmployerId'?: number;
'BaseData.EmployerRelationship.Id'?: number;
'BaseData.EmployerRelationship.Name'?: string;
'BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.EmployerRelationshipId'?: number;
'BaseData.Recruiter.Id'?: number;
'BaseData.Recruiter.Name'?: string;
'BaseData.Recruiter.Active'?: boolean;
'BaseData.Recruiter.Email'?: string;
'BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.AdvertisementId'?: string;
'BaseData.Referrer.Id'?: number;
'BaseData.Referrer.State'?: UserState;
'BaseData.Referrer.Password'?: string;
'BaseData.Referrer.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.RoleName'?: string;
'BaseData.Referrer.LocalizedRoleName'?: string;
'BaseData.Referrer.Role.Id'?: number;
'BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.Role.RoleName'?: string;
'BaseData.Referrer.Role.Description'?: string;
'BaseData.Referrer.Role.Order'?: number;
'BaseData.Referrer.Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.Referrer.BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.Referrer.BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.Referrer.BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.Referrer.BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.Referrer.BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.Referrer.BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.Referrer.BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.Referrer.BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.Referrer.BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.Referrer.BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.Referrer.BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.Referrer.BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.Referrer.BaseData.Nationality'?: string;
'BaseData.Referrer.BaseData.IDType.Id'?: number;
'BaseData.Referrer.BaseData.IDType.Name'?: string;
'BaseData.Referrer.BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.Referrer.BaseData.IDNumber'?: string;
'BaseData.Referrer.BaseData.Employer.Id'?: number;
'BaseData.Referrer.BaseData.Employer.Name'?: string;
'BaseData.Referrer.BaseData.Employer.Active'?: boolean;
'BaseData.Referrer.BaseData.Employer.Email'?: string;
'BaseData.Referrer.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.Referrer.BaseData.EmployerId'?: number;
'BaseData.Referrer.BaseData.EmployerRelationship.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRelationship.Name'?: string;
'BaseData.Referrer.BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.Referrer.BaseData.EmployerRelationshipId'?: number;
'BaseData.Referrer.BaseData.Recruiter.Id'?: number;
'BaseData.Referrer.BaseData.Recruiter.Name'?: string;
'BaseData.Referrer.BaseData.Recruiter.Active'?: boolean;
'BaseData.Referrer.BaseData.Recruiter.Email'?: string;
'BaseData.Referrer.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.Referrer.BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.Referrer.BaseData.AdvertisementId'?: string;
'BaseData.Referrer.BaseData.Referrer'?: UserData;
'BaseData.Referrer.BaseData.ReferrerId'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.State'?: UserState;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Password'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.RoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Description'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Order'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.Referrer.BaseData.EmployerRegistrationTime'?: string;
'BaseData.Referrer.BaseData.ProfilePicture'?: string;
'BaseData.Referrer.BaseData.LastActivity'?: string;
'BaseData.Referrer.BaseData.ContactUser.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.State'?: UserState;
'BaseData.Referrer.BaseData.ContactUser.Password'?: string;
'BaseData.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.ContactUser.RoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.RoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.Description'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.Order'?: number;
'BaseData.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthCountry'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthPlace'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthDate'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersFullName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersSurName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempAddress'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.Description'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.LocalizedState'?: string;
'BaseData.Referrer.BaseData.ContactUser.RegistrationLink'?: string;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ProfilePictureExists'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.PrivateDescription'?: string;
'BaseData.Referrer.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
'BaseData.Referrer.BaseData.ContactUser.NextStateDate'?: string;
'BaseData.Referrer.BaseData.ContactUserId'?: number;
'BaseData.Referrer.BaseData.LastStateChange'?: string;
'BaseData.Referrer.BaseData.LastStateReactivation'?: string;
'BaseData.Referrer.BaseData.TestScore'?: number;
'BaseData.Referrer.BaseData.EmailVerified'?: boolean;
'BaseData.Referrer.BaseData.PhoneNumberVerified'?: boolean;
'BaseData.Referrer.BaseData.PaymentSchedule.Id'?: number;
'BaseData.Referrer.BaseData.PaymentSchedule.Name'?: string;
'BaseData.Referrer.BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.Referrer.BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BaseData.Referrer.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.Referrer.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.Referrer.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.Referrer.BirthData.BirthFirstName2'?: string;
'BaseData.Referrer.BirthData.BirthCountry'?: string;
'BaseData.Referrer.BirthData.BirthPlace'?: string;
'BaseData.Referrer.BirthData.BirthDate'?: string;
'BaseData.Referrer.BirthData.MothersFullName'?: string;
'BaseData.Referrer.BirthData.MothersSurName'?: string;
'BaseData.Referrer.BirthData.MothersFirstName'?: string;
'BaseData.Referrer.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.Referrer.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.Referrer.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.Referrer.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.Referrer.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.Referrer.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.Referrer.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.Referrer.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.Referrer.AddressData.TempAddress'?: string;
'BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
'BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
'BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.Referrer.ExtendedData.ShoeSize'?: number;
'BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
'BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
'BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
'BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
'BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
'BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
'BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
'BaseData.Referrer.ExtendedData.Description'?: string;
'BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
'BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
'BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.Referrer.LocalizedState'?: string;
'BaseData.Referrer.RegistrationLink'?: string;
'BaseData.Referrer.NextStateProperties.NextState'?: UserState;
'BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
'BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.Referrer.RegistrationLinkSent'?: boolean;
'BaseData.Referrer.ProfilePictureExists'?: boolean;
'BaseData.Referrer.PrivateDescription'?: string;
'BaseData.Referrer.StateBeforeInactivation'?: UserState;
'BaseData.Referrer.NextStateDate'?: string;
'BaseData.ReferrerId'?: number;
'BaseData.EmployerRegistrationUser.Id'?: number;
'BaseData.EmployerRegistrationUser.State'?: UserState;
'BaseData.EmployerRegistrationUser.Password'?: string;
'BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.RoleName'?: string;
'BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.Role.Id'?: number;
'BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
'BaseData.EmployerRegistrationUser.Role.Description'?: string;
'BaseData.EmployerRegistrationUser.Role.Order'?: number;
'BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.EmployerRegistrationUser.BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.EmployerRegistrationUser.BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.EmployerRegistrationUser.BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.EmployerRegistrationUser.BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.EmployerRegistrationUser.BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.EmployerRegistrationUser.BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.EmployerRegistrationUser.BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.EmployerRegistrationUser.BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.EmployerRegistrationUser.BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.EmployerRegistrationUser.BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.EmployerRegistrationUser.BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.EmployerRegistrationUser.BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.EmployerRegistrationUser.BaseData.Nationality'?: string;
'BaseData.EmployerRegistrationUser.BaseData.IDType.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.IDType.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.EmployerRegistrationUser.BaseData.IDNumber'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Email'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.EmployerRegistrationUser.BaseData.EmployerId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationship.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationship.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationshipId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Email'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.EmployerRegistrationUser.BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.EmployerRegistrationUser.BaseData.AdvertisementId'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.State'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Password'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Order'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempAddress'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.LocalizedState'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.RegistrationLink'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.PrivateDescription'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ReferrerId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRegistrationUser'?: UserData;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.EmployerRegistrationUser.BaseData.EmployerRegistrationTime'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ProfilePicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.LastActivity'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.State'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Password'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Order'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthCountry'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthPlace'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersFullName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersSurName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempAddress'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.LocalizedState'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.RegistrationLink'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ProfilePictureExists'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.PrivateDescription'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUserId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.LastStateChange'?: string;
'BaseData.EmployerRegistrationUser.BaseData.LastStateReactivation'?: string;
'BaseData.EmployerRegistrationUser.BaseData.TestScore'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmailVerified'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.PhoneNumberVerified'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.PaymentSchedule.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.PaymentSchedule.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.EmployerRegistrationUser.BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
'BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
'BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
'BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.EmployerRegistrationUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
'BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
'BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.LocalizedState'?: string;
'BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
'BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
'BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
'BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
'BaseData.EmployerRegistrationUser.NextStateDate'?: string;
'BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.EmployerRegistrationTime'?: string;
'BaseData.ProfilePicture'?: string;
'BaseData.LastActivity'?: string;
'BaseData.ContactUser.Id'?: number;
'BaseData.ContactUser.State'?: UserState;
'BaseData.ContactUser.Password'?: string;
'BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.RoleName'?: string;
'BaseData.ContactUser.LocalizedRoleName'?: string;
'BaseData.ContactUser.Role.Id'?: number;
'BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.Role.RoleName'?: string;
'BaseData.ContactUser.Role.Description'?: string;
'BaseData.ContactUser.Role.Order'?: number;
'BaseData.ContactUser.Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.ContactUser.BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.ContactUser.BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.ContactUser.BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.ContactUser.BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.ContactUser.BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.ContactUser.BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.ContactUser.BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.ContactUser.BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.ContactUser.BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.ContactUser.BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.ContactUser.BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.ContactUser.BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.ContactUser.BaseData.Nationality'?: string;
'BaseData.ContactUser.BaseData.IDType.Id'?: number;
'BaseData.ContactUser.BaseData.IDType.Name'?: string;
'BaseData.ContactUser.BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.ContactUser.BaseData.IDNumber'?: string;
'BaseData.ContactUser.BaseData.Employer.Id'?: number;
'BaseData.ContactUser.BaseData.Employer.Name'?: string;
'BaseData.ContactUser.BaseData.Employer.Active'?: boolean;
'BaseData.ContactUser.BaseData.Employer.Email'?: string;
'BaseData.ContactUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.ContactUser.BaseData.EmployerId'?: number;
'BaseData.ContactUser.BaseData.EmployerRelationship.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRelationship.Name'?: string;
'BaseData.ContactUser.BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRelationshipId'?: number;
'BaseData.ContactUser.BaseData.Recruiter.Id'?: number;
'BaseData.ContactUser.BaseData.Recruiter.Name'?: string;
'BaseData.ContactUser.BaseData.Recruiter.Active'?: boolean;
'BaseData.ContactUser.BaseData.Recruiter.Email'?: string;
'BaseData.ContactUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.ContactUser.BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.ContactUser.BaseData.AdvertisementId'?: string;
'BaseData.ContactUser.BaseData.Referrer.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.State'?: UserState;
'BaseData.ContactUser.BaseData.Referrer.Password'?: string;
'BaseData.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.Referrer.RoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.RoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.Description'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.Order'?: number;
'BaseData.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthDate'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempAddress'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.Description'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.LocalizedState'?: string;
'BaseData.ContactUser.BaseData.Referrer.RegistrationLink'?: string;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.PrivateDescription'?: string;
'BaseData.ContactUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
'BaseData.ContactUser.BaseData.Referrer.NextStateDate'?: string;
'BaseData.ContactUser.BaseData.ReferrerId'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.State'?: UserState;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Password'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.RoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationTime'?: string;
'BaseData.ContactUser.BaseData.ProfilePicture'?: string;
'BaseData.ContactUser.BaseData.LastActivity'?: string;
'BaseData.ContactUser.BaseData.ContactUser'?: UserData;
'BaseData.ContactUser.BaseData.ContactUserId'?: number;
'BaseData.ContactUser.BaseData.LastStateChange'?: string;
'BaseData.ContactUser.BaseData.LastStateReactivation'?: string;
'BaseData.ContactUser.BaseData.TestScore'?: number;
'BaseData.ContactUser.BaseData.EmailVerified'?: boolean;
'BaseData.ContactUser.BaseData.PhoneNumberVerified'?: boolean;
'BaseData.ContactUser.BaseData.PaymentSchedule.Id'?: number;
'BaseData.ContactUser.BaseData.PaymentSchedule.Name'?: string;
'BaseData.ContactUser.BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.ContactUser.BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BaseData.ContactUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.ContactUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.ContactUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
'BaseData.ContactUser.BirthData.BirthCountry'?: string;
'BaseData.ContactUser.BirthData.BirthPlace'?: string;
'BaseData.ContactUser.BirthData.BirthDate'?: string;
'BaseData.ContactUser.BirthData.MothersFullName'?: string;
'BaseData.ContactUser.BirthData.MothersSurName'?: string;
'BaseData.ContactUser.BirthData.MothersFirstName'?: string;
'BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.ContactUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.ContactUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.ContactUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.ContactUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.ContactUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.ContactUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.ContactUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.ContactUser.AddressData.TempAddress'?: string;
'BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
'BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
'BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
'BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
'BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.ContactUser.ExtendedData.Description'?: string;
'BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.ContactUser.LocalizedState'?: string;
'BaseData.ContactUser.RegistrationLink'?: string;
'BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
'BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.ContactUser.RegistrationLinkSent'?: boolean;
'BaseData.ContactUser.ProfilePictureExists'?: boolean;
'BaseData.ContactUser.PrivateDescription'?: string;
'BaseData.ContactUser.StateBeforeInactivation'?: UserState;
'BaseData.ContactUser.NextStateDate'?: string;
'BaseData.ContactUserId'?: number;
'BaseData.LastStateChange'?: string;
'BaseData.LastStateReactivation'?: string;
'BaseData.TestScore'?: number;
'BaseData.EmailVerified'?: boolean;
'BaseData.PhoneNumberVerified'?: boolean;
'BaseData.PaymentSchedule.Id'?: number;
'BaseData.PaymentSchedule.Name'?: string;
'BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BirthData.BirthFirstName2'?: string;
'BirthData.BirthCountry'?: string;
'BirthData.BirthPlace'?: string;
'BirthData.BirthDate'?: string;
'BirthData.MothersFullName'?: string;
'BirthData.MothersSurName'?: string;
'BirthData.MothersFirstName'?: string;
'BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'AddressData.City'?: string;

/**
 * Lakcím cím
 */
'AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'AddressData.TempAddress'?: string;
'ExtendedData.SchoolType.Id'?: number;
'ExtendedData.SchoolType.Name'?: string;
'ExtendedData.SchoolType.Active'?: boolean;
'ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'ExtendedData.Shifts'?: Array<ShiftData>;
'ExtendedData.ShoeSize'?: number;
'ExtendedData.DressSize.Id'?: number;
'ExtendedData.DressSize.Name'?: string;
'ExtendedData.DressSize.Active'?: boolean;
'ExtendedData.OwnCar'?: boolean;
'ExtendedData.OwnShoe'?: boolean;
'ExtendedData.HealthRecordDate'?: string;
'ExtendedData.LungScreeningDate'?: string;
'ExtendedData.Description'?: string;
'ExtendedData.GDPRAccepted'?: boolean;
'ExtendedData.Labels'?: Array<LabelData>;
'ExtendedData.LungScreeningPicture'?: string;
'ExtendedData.HealthRecordPicture'?: string;
'ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'LocalizedState'?: string;
'RegistrationLink'?: string;
'NextStateProperties.NextState'?: UserState;
'NextStateProperties.NextStateLocalized'?: string;
'NextStateProperties.NextStateButtonTextLocalized'?: string;
'NextStateProperties.NextStateButtonVisible'?: boolean;
'NextStateProperties.NextStateVisible'?: boolean;
'RegistrationLinkSent'?: boolean;
'ProfilePictureExists'?: boolean;
'PrivateDescription'?: string;
'StateBeforeInactivation'?: UserState;
'NextStateDate'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználó létrehozása vagy módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  userPost(params?: {
    body?: {
'Id'?: number;
'UserCards'?: Array<UserCardData>;
'ProfilePicture'?: Blob;
'ProfilePictureRotationDegree'?: number;
'LungScreeningPicture'?: Blob;
'HealthRecordPicture'?: Blob;
'RequestUserId'?: number;
'SetAdmissioned'?: boolean;
'KeepFutureEvents'?: boolean;
'TimeZoneId'?: string;
'State'?: UserState;
'Password'?: string;
'Permissions.GlobalPermissions'?: string;
'RoleName'?: string;
'LocalizedRoleName'?: string;
'Role.Id'?: number;
'Role.Permissions.GlobalPermissions'?: string;
'Role.RoleName'?: string;
'Role.Description'?: string;
'Role.Order'?: number;
'Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.Nationality'?: string;
'BaseData.IDType.Id'?: number;
'BaseData.IDType.Name'?: string;
'BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.IDNumber'?: string;
'BaseData.Employer.Id'?: number;
'BaseData.Employer.Name'?: string;
'BaseData.Employer.Active'?: boolean;
'BaseData.Employer.Email'?: string;
'BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.EmployerId'?: number;
'BaseData.EmployerRelationship.Id'?: number;
'BaseData.EmployerRelationship.Name'?: string;
'BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.EmployerRelationshipId'?: number;
'BaseData.Recruiter.Id'?: number;
'BaseData.Recruiter.Name'?: string;
'BaseData.Recruiter.Active'?: boolean;
'BaseData.Recruiter.Email'?: string;
'BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.AdvertisementId'?: string;
'BaseData.Referrer.Id'?: number;
'BaseData.Referrer.State'?: UserState;
'BaseData.Referrer.Password'?: string;
'BaseData.Referrer.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.RoleName'?: string;
'BaseData.Referrer.LocalizedRoleName'?: string;
'BaseData.Referrer.Role.Id'?: number;
'BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.Role.RoleName'?: string;
'BaseData.Referrer.Role.Description'?: string;
'BaseData.Referrer.Role.Order'?: number;
'BaseData.Referrer.Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.Referrer.BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.Referrer.BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.Referrer.BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.Referrer.BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.Referrer.BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.Referrer.BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.Referrer.BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.Referrer.BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.Referrer.BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.Referrer.BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.Referrer.BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.Referrer.BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.Referrer.BaseData.Nationality'?: string;
'BaseData.Referrer.BaseData.IDType.Id'?: number;
'BaseData.Referrer.BaseData.IDType.Name'?: string;
'BaseData.Referrer.BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.Referrer.BaseData.IDNumber'?: string;
'BaseData.Referrer.BaseData.Employer.Id'?: number;
'BaseData.Referrer.BaseData.Employer.Name'?: string;
'BaseData.Referrer.BaseData.Employer.Active'?: boolean;
'BaseData.Referrer.BaseData.Employer.Email'?: string;
'BaseData.Referrer.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.Referrer.BaseData.EmployerId'?: number;
'BaseData.Referrer.BaseData.EmployerRelationship.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRelationship.Name'?: string;
'BaseData.Referrer.BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.Referrer.BaseData.EmployerRelationshipId'?: number;
'BaseData.Referrer.BaseData.Recruiter.Id'?: number;
'BaseData.Referrer.BaseData.Recruiter.Name'?: string;
'BaseData.Referrer.BaseData.Recruiter.Active'?: boolean;
'BaseData.Referrer.BaseData.Recruiter.Email'?: string;
'BaseData.Referrer.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.Referrer.BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.Referrer.BaseData.AdvertisementId'?: string;
'BaseData.Referrer.BaseData.Referrer'?: UserData;
'BaseData.Referrer.BaseData.ReferrerId'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.State'?: UserState;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Password'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.RoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Description'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.Order'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
'BaseData.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
'BaseData.Referrer.BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.Referrer.BaseData.EmployerRegistrationTime'?: string;
'BaseData.Referrer.BaseData.ProfilePicture'?: string;
'BaseData.Referrer.BaseData.LastActivity'?: string;
'BaseData.Referrer.BaseData.ContactUser.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.State'?: UserState;
'BaseData.Referrer.BaseData.ContactUser.Password'?: string;
'BaseData.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.ContactUser.RoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.RoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.Description'?: string;
'BaseData.Referrer.BaseData.ContactUser.Role.Order'?: number;
'BaseData.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthCountry'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthPlace'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.BirthDate'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersFullName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersSurName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
'BaseData.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.Referrer.BaseData.ContactUser.AddressData.TempAddress'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.Description'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.LocalizedState'?: string;
'BaseData.Referrer.BaseData.ContactUser.RegistrationLink'?: string;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.ProfilePictureExists'?: boolean;
'BaseData.Referrer.BaseData.ContactUser.PrivateDescription'?: string;
'BaseData.Referrer.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
'BaseData.Referrer.BaseData.ContactUser.NextStateDate'?: string;
'BaseData.Referrer.BaseData.ContactUserId'?: number;
'BaseData.Referrer.BaseData.LastStateChange'?: string;
'BaseData.Referrer.BaseData.LastStateReactivation'?: string;
'BaseData.Referrer.BaseData.TestScore'?: number;
'BaseData.Referrer.BaseData.EmailVerified'?: boolean;
'BaseData.Referrer.BaseData.PhoneNumberVerified'?: boolean;
'BaseData.Referrer.BaseData.PaymentSchedule.Id'?: number;
'BaseData.Referrer.BaseData.PaymentSchedule.Name'?: string;
'BaseData.Referrer.BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.Referrer.BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BaseData.Referrer.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.Referrer.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.Referrer.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.Referrer.BirthData.BirthFirstName2'?: string;
'BaseData.Referrer.BirthData.BirthCountry'?: string;
'BaseData.Referrer.BirthData.BirthPlace'?: string;
'BaseData.Referrer.BirthData.BirthDate'?: string;
'BaseData.Referrer.BirthData.MothersFullName'?: string;
'BaseData.Referrer.BirthData.MothersSurName'?: string;
'BaseData.Referrer.BirthData.MothersFirstName'?: string;
'BaseData.Referrer.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.Referrer.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.Referrer.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.Referrer.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.Referrer.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.Referrer.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.Referrer.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.Referrer.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.Referrer.AddressData.TempAddress'?: string;
'BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
'BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
'BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.Referrer.ExtendedData.ShoeSize'?: number;
'BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
'BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
'BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
'BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
'BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
'BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
'BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
'BaseData.Referrer.ExtendedData.Description'?: string;
'BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
'BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
'BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.Referrer.LocalizedState'?: string;
'BaseData.Referrer.RegistrationLink'?: string;
'BaseData.Referrer.NextStateProperties.NextState'?: UserState;
'BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
'BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.Referrer.RegistrationLinkSent'?: boolean;
'BaseData.Referrer.ProfilePictureExists'?: boolean;
'BaseData.Referrer.PrivateDescription'?: string;
'BaseData.Referrer.StateBeforeInactivation'?: UserState;
'BaseData.Referrer.NextStateDate'?: string;
'BaseData.ReferrerId'?: number;
'BaseData.EmployerRegistrationUser.Id'?: number;
'BaseData.EmployerRegistrationUser.State'?: UserState;
'BaseData.EmployerRegistrationUser.Password'?: string;
'BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.RoleName'?: string;
'BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.Role.Id'?: number;
'BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
'BaseData.EmployerRegistrationUser.Role.Description'?: string;
'BaseData.EmployerRegistrationUser.Role.Order'?: number;
'BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.EmployerRegistrationUser.BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.EmployerRegistrationUser.BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.EmployerRegistrationUser.BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.EmployerRegistrationUser.BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.EmployerRegistrationUser.BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.EmployerRegistrationUser.BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.EmployerRegistrationUser.BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.EmployerRegistrationUser.BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.EmployerRegistrationUser.BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.EmployerRegistrationUser.BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.EmployerRegistrationUser.BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.EmployerRegistrationUser.BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.EmployerRegistrationUser.BaseData.Nationality'?: string;
'BaseData.EmployerRegistrationUser.BaseData.IDType.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.IDType.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.EmployerRegistrationUser.BaseData.IDNumber'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Employer.Email'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.EmployerRegistrationUser.BaseData.EmployerId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationship.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationship.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRelationshipId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.Email'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.EmployerRegistrationUser.BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.EmployerRegistrationUser.BaseData.AdvertisementId'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.State'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Password'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.Order'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.BirthDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.EmployerRegistrationUser.BaseData.Referrer.AddressData.TempAddress'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.LocalizedState'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.RegistrationLink'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.PrivateDescription'?: string;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.Referrer.NextStateDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ReferrerId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRegistrationUser'?: UserData;
'BaseData.EmployerRegistrationUser.BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.EmployerRegistrationUser.BaseData.EmployerRegistrationTime'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ProfilePicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.LastActivity'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.State'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Password'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.RoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.Order'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthCountry'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthPlace'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.BirthDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersFullName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersSurName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.AddressData.TempAddress'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.Description'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.LocalizedState'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.RegistrationLink'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.ProfilePictureExists'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.PrivateDescription'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
'BaseData.EmployerRegistrationUser.BaseData.ContactUser.NextStateDate'?: string;
'BaseData.EmployerRegistrationUser.BaseData.ContactUserId'?: number;
'BaseData.EmployerRegistrationUser.BaseData.LastStateChange'?: string;
'BaseData.EmployerRegistrationUser.BaseData.LastStateReactivation'?: string;
'BaseData.EmployerRegistrationUser.BaseData.TestScore'?: number;
'BaseData.EmployerRegistrationUser.BaseData.EmailVerified'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.PhoneNumberVerified'?: boolean;
'BaseData.EmployerRegistrationUser.BaseData.PaymentSchedule.Id'?: number;
'BaseData.EmployerRegistrationUser.BaseData.PaymentSchedule.Name'?: string;
'BaseData.EmployerRegistrationUser.BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.EmployerRegistrationUser.BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
'BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
'BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
'BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
'BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.EmployerRegistrationUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
'BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
'BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.EmployerRegistrationUser.LocalizedState'?: string;
'BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
'BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
'BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
'BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
'BaseData.EmployerRegistrationUser.NextStateDate'?: string;
'BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.EmployerRegistrationTime'?: string;
'BaseData.ProfilePicture'?: string;
'BaseData.LastActivity'?: string;
'BaseData.ContactUser.Id'?: number;
'BaseData.ContactUser.State'?: UserState;
'BaseData.ContactUser.Password'?: string;
'BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.RoleName'?: string;
'BaseData.ContactUser.LocalizedRoleName'?: string;
'BaseData.ContactUser.Role.Id'?: number;
'BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.Role.RoleName'?: string;
'BaseData.ContactUser.Role.Description'?: string;
'BaseData.ContactUser.Role.Order'?: number;
'BaseData.ContactUser.Role.LocalizedRoleName'?: string;

/**
 * Teljes név
 */
'BaseData.ContactUser.BaseData.FullName'?: string;

/**
 * M kód
 */
'BaseData.ContactUser.BaseData.MCode'?: string;

/**
 * Vezetéknév
 */
'BaseData.ContactUser.BaseData.Surname'?: string;

/**
 * Utónév
 */
'BaseData.ContactUser.BaseData.Firstname'?: string;

/**
 * Utónév 2
 */
'BaseData.ContactUser.BaseData.Firstname2'?: string;

/**
 * Adóazonosító jel
 */
'BaseData.ContactUser.BaseData.TaxNumber'?: string;

/**
 * Telefonszám
 */
'BaseData.ContactUser.BaseData.PhoneNumber'?: string;

/**
 * Telefonszám ország hívókód
 */
'BaseData.ContactUser.BaseData.PhoneNumberCountryCode'?: string;

/**
 * Email cím
 */
'BaseData.ContactUser.BaseData.Email'?: string;

/**
 * Nem
 */
'BaseData.ContactUser.BaseData.Gender'?: boolean;

/**
 * TAJ szám
 */
'BaseData.ContactUser.BaseData.SocialSecurityNumber'?: string;

/**
 * Bankszámla szám
 */
'BaseData.ContactUser.BaseData.BankAccount'?: string;

/**
 * Állampolgárság
 */
'BaseData.ContactUser.BaseData.Nationality'?: string;
'BaseData.ContactUser.BaseData.IDType.Id'?: number;
'BaseData.ContactUser.BaseData.IDType.Name'?: string;
'BaseData.ContactUser.BaseData.IDType.Active'?: boolean;

/**
 * Okmány száma
 */
'BaseData.ContactUser.BaseData.IDNumber'?: string;
'BaseData.ContactUser.BaseData.Employer.Id'?: number;
'BaseData.ContactUser.BaseData.Employer.Name'?: string;
'BaseData.ContactUser.BaseData.Employer.Active'?: boolean;
'BaseData.ContactUser.BaseData.Employer.Email'?: string;
'BaseData.ContactUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.ContactUser.BaseData.EmployerId'?: number;
'BaseData.ContactUser.BaseData.EmployerRelationship.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRelationship.Name'?: string;
'BaseData.ContactUser.BaseData.EmployerRelationship.Active'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRelationshipId'?: number;
'BaseData.ContactUser.BaseData.Recruiter.Id'?: number;
'BaseData.ContactUser.BaseData.Recruiter.Name'?: string;
'BaseData.ContactUser.BaseData.Recruiter.Active'?: boolean;
'BaseData.ContactUser.BaseData.Recruiter.Email'?: string;
'BaseData.ContactUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
'BaseData.ContactUser.BaseData.RecruiterId'?: number;

/**
 * Hirdetés azonosító
 */
'BaseData.ContactUser.BaseData.AdvertisementId'?: string;
'BaseData.ContactUser.BaseData.Referrer.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.State'?: UserState;
'BaseData.ContactUser.BaseData.Referrer.Password'?: string;
'BaseData.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.Referrer.RoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.RoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.Description'?: string;
'BaseData.ContactUser.BaseData.Referrer.Role.Order'?: number;
'BaseData.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.BirthDate'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
'BaseData.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.ContactUser.BaseData.Referrer.AddressData.TempAddress'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.Description'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.LocalizedState'?: string;
'BaseData.ContactUser.BaseData.Referrer.RegistrationLink'?: string;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
'BaseData.ContactUser.BaseData.Referrer.PrivateDescription'?: string;
'BaseData.ContactUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
'BaseData.ContactUser.BaseData.Referrer.NextStateDate'?: string;
'BaseData.ContactUser.BaseData.ReferrerId'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.State'?: UserState;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Password'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.RoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

/**
 * Születési teljes név
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
'BaseData.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
'BaseData.ContactUser.BaseData.EmployerRegistrationUserId'?: number;

/**
 * Munkáltatói beléptetés időpontja
 */
'BaseData.ContactUser.BaseData.EmployerRegistrationTime'?: string;
'BaseData.ContactUser.BaseData.ProfilePicture'?: string;
'BaseData.ContactUser.BaseData.LastActivity'?: string;
'BaseData.ContactUser.BaseData.ContactUser'?: UserData;
'BaseData.ContactUser.BaseData.ContactUserId'?: number;
'BaseData.ContactUser.BaseData.LastStateChange'?: string;
'BaseData.ContactUser.BaseData.LastStateReactivation'?: string;
'BaseData.ContactUser.BaseData.TestScore'?: number;
'BaseData.ContactUser.BaseData.EmailVerified'?: boolean;
'BaseData.ContactUser.BaseData.PhoneNumberVerified'?: boolean;
'BaseData.ContactUser.BaseData.PaymentSchedule.Id'?: number;
'BaseData.ContactUser.BaseData.PaymentSchedule.Name'?: string;
'BaseData.ContactUser.BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.ContactUser.BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BaseData.ContactUser.BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BaseData.ContactUser.BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BaseData.ContactUser.BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
'BaseData.ContactUser.BirthData.BirthCountry'?: string;
'BaseData.ContactUser.BirthData.BirthPlace'?: string;
'BaseData.ContactUser.BirthData.BirthDate'?: string;
'BaseData.ContactUser.BirthData.MothersFullName'?: string;
'BaseData.ContactUser.BirthData.MothersSurName'?: string;
'BaseData.ContactUser.BirthData.MothersFirstName'?: string;
'BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'BaseData.ContactUser.AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'BaseData.ContactUser.AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'BaseData.ContactUser.AddressData.City'?: string;

/**
 * Lakcím cím
 */
'BaseData.ContactUser.AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'BaseData.ContactUser.AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'BaseData.ContactUser.AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'BaseData.ContactUser.AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'BaseData.ContactUser.AddressData.TempAddress'?: string;
'BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
'BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
'BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
'BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
'BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
'BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
'BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
'BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
'BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
'BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
'BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
'BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
'BaseData.ContactUser.ExtendedData.Description'?: string;
'BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
'BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
'BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
'BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
'BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'BaseData.ContactUser.LocalizedState'?: string;
'BaseData.ContactUser.RegistrationLink'?: string;
'BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
'BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
'BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
'BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
'BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
'BaseData.ContactUser.RegistrationLinkSent'?: boolean;
'BaseData.ContactUser.ProfilePictureExists'?: boolean;
'BaseData.ContactUser.PrivateDescription'?: string;
'BaseData.ContactUser.StateBeforeInactivation'?: UserState;
'BaseData.ContactUser.NextStateDate'?: string;
'BaseData.ContactUserId'?: number;
'BaseData.LastStateChange'?: string;
'BaseData.LastStateReactivation'?: string;
'BaseData.TestScore'?: number;
'BaseData.EmailVerified'?: boolean;
'BaseData.PhoneNumberVerified'?: boolean;
'BaseData.PaymentSchedule.Id'?: number;
'BaseData.PaymentSchedule.Name'?: string;
'BaseData.PaymentSchedule.Active'?: boolean;

/**
 * Bérkód
 */
'BaseData.WageCodeUser'?: string;

/**
 * Születési teljes név
 */
'BirthData.BirthFullName'?: string;

/**
 * Születési vezetéknév
 */
'BirthData.BirthSurname'?: string;

/**
 * Születési utónév
 */
'BirthData.BirthFirstName'?: string;

/**
 * Születési Utónév 2
 */
'BirthData.BirthFirstName2'?: string;
'BirthData.BirthCountry'?: string;
'BirthData.BirthPlace'?: string;
'BirthData.BirthDate'?: string;
'BirthData.MothersFullName'?: string;
'BirthData.MothersSurName'?: string;
'BirthData.MothersFirstName'?: string;
'BirthData.MothersFirstName2'?: string;

/**
 * Lakcím ország
 */
'AddressData.Country'?: string;

/**
 * Lakcím irsz
 */
'AddressData.Postcode'?: string;

/**
 * Lakcím település
 */
'AddressData.City'?: string;

/**
 * Lakcím cím
 */
'AddressData.Address'?: string;

/**
 * Ideiglenes cím ország
 */
'AddressData.TempCountry'?: string;

/**
 * Ideiglenes cím irsz
 */
'AddressData.TempPostcode'?: string;

/**
 * Ideiglenes cím település
 */
'AddressData.TempCity'?: string;

/**
 * Ideiglenes cím
 */
'AddressData.TempAddress'?: string;
'ExtendedData.SchoolType.Id'?: number;
'ExtendedData.SchoolType.Name'?: string;
'ExtendedData.SchoolType.Active'?: boolean;
'ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
'ExtendedData.Shifts'?: Array<ShiftData>;
'ExtendedData.ShoeSize'?: number;
'ExtendedData.DressSize.Id'?: number;
'ExtendedData.DressSize.Name'?: string;
'ExtendedData.DressSize.Active'?: boolean;
'ExtendedData.OwnCar'?: boolean;
'ExtendedData.OwnShoe'?: boolean;
'ExtendedData.HealthRecordDate'?: string;
'ExtendedData.LungScreeningDate'?: string;
'ExtendedData.Description'?: string;
'ExtendedData.GDPRAccepted'?: boolean;
'ExtendedData.Labels'?: Array<LabelData>;
'ExtendedData.LungScreeningPicture'?: string;
'ExtendedData.HealthRecordPicture'?: string;
'ExtendedData.ForbiddenLabels'?: Array<LabelData>;
'ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
'LocalizedState'?: string;
'RegistrationLink'?: string;
'NextStateProperties.NextState'?: UserState;
'NextStateProperties.NextStateLocalized'?: string;
'NextStateProperties.NextStateButtonTextLocalized'?: string;
'NextStateProperties.NextStateButtonVisible'?: boolean;
'NextStateProperties.NextStateVisible'?: boolean;
'RegistrationLinkSent'?: boolean;
'ProfilePictureExists'?: boolean;
'PrivateDescription'?: string;
'StateBeforeInactivation'?: UserState;
'NextStateDate'?: string;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.userPost$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation delete
   */
  static readonly DeletePath = '/api/User/Delete';

  /**
   * Kijelölt felhasználók törlése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete$Response(params?: {
    body?: DeleteUsersModel
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.DeletePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Kijelölt felhasználók törlése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete(params?: {
    body?: DeleteUsersModel
  },
  context?: HttpContext

): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation selectAll
   */
  static readonly SelectAllPath = '/api/User/SelectAll';

  /**
   * Felhasználók azonosítóinak lekérése kiválasztásra.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectAll$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectAll$Plain$Response(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    ExcludeProject?: number;
    InRoles?: Array<number>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SelectAllUsersResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.SelectAllPath, 'get');
    if (params) {
      rb.query('ActiveOnly', params.ActiveOnly, {});
      rb.query('IncludeBuiltIn', params.IncludeBuiltIn, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('ExcludeProject', params.ExcludeProject, {});
      rb.query('InRoles', params.InRoles, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SelectAllUsersResult>;
      })
    );
  }

  /**
   * Felhasználók azonosítóinak lekérése kiválasztásra.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `selectAll$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectAll$Plain(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    ExcludeProject?: number;
    InRoles?: Array<number>;
  },
  context?: HttpContext

): Observable<SelectAllUsersResult> {

    return this.selectAll$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SelectAllUsersResult>) => r.body as SelectAllUsersResult)
    );
  }

  /**
   * Felhasználók azonosítóinak lekérése kiválasztásra.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectAll$Response(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    ExcludeProject?: number;
    InRoles?: Array<number>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SelectAllUsersResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.SelectAllPath, 'get');
    if (params) {
      rb.query('ActiveOnly', params.ActiveOnly, {});
      rb.query('IncludeBuiltIn', params.IncludeBuiltIn, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('ExcludeProject', params.ExcludeProject, {});
      rb.query('InRoles', params.InRoles, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SelectAllUsersResult>;
      })
    );
  }

  /**
   * Felhasználók azonosítóinak lekérése kiválasztásra.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `selectAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectAll(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    ExcludeProject?: number;
    InRoles?: Array<number>;
  },
  context?: HttpContext

): Observable<SelectAllUsersResult> {

    return this.selectAll$Response(params,context).pipe(
      map((r: StrictHttpResponse<SelectAllUsersResult>) => r.body as SelectAllUsersResult)
    );
  }

  /**
   * Path part for operation userExport
   */
  static readonly UserExportPath = '/api/User/Export';

  /**
   * Felhasználók exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userExport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExport$Plain$Response(params?: {
    body?: ExportUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználók exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userExport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExport$Plain(params?: {
    body?: ExportUsersQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userExport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Felhasználók exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userExport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExport$Response(params?: {
    body?: ExportUsersQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználók exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userExport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExport(params?: {
    body?: ExportUsersQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userExport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation userExportUserData
   */
  static readonly UserExportUserDataPath = '/api/User/ExportUserData';

  /**
   * Felhasználói azonosítók exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userExportUserData$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExportUserData$Plain$Response(params?: {
    body?: ExportUserDataQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserExportUserDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználói azonosítók exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userExportUserData$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExportUserData$Plain(params?: {
    body?: ExportUserDataQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userExportUserData$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Felhasználói azonosítók exportálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userExportUserData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExportUserData$Response(params?: {
    body?: ExportUserDataQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserExportUserDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználói azonosítók exportálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userExportUserData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userExportUserData(params?: {
    body?: ExportUserDataQuery
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userExportUserData$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation userImport
   */
  static readonly UserImportPath = '/api/User/Import';

  /**
   * Felhasználói azonosítók importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userImport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userImport$Plain$Response(params?: {
    body?: ImportUserDataCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedUserDataLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedUserDataLineResult>>;
      })
    );
  }

  /**
   * Felhasználói azonosítók importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userImport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userImport$Plain(params?: {
    body?: ImportUserDataCommand
  },
  context?: HttpContext

): Observable<Array<ImportedUserDataLineResult>> {

    return this.userImport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedUserDataLineResult>>) => r.body as Array<ImportedUserDataLineResult>)
    );
  }

  /**
   * Felhasználói azonosítók importálása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userImport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userImport$Response(params?: {
    body?: ImportUserDataCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ImportedUserDataLineResult>>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserImportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ImportedUserDataLineResult>>;
      })
    );
  }

  /**
   * Felhasználói azonosítók importálása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userImport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userImport(params?: {
    body?: ImportUserDataCommand
  },
  context?: HttpContext

): Observable<Array<ImportedUserDataLineResult>> {

    return this.userImport$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ImportedUserDataLineResult>>) => r.body as Array<ImportedUserDataLineResult>)
    );
  }

  /**
   * Path part for operation userSelect
   */
  static readonly UserSelectPath = '/api/User/Select';

  /**
   * Felhasználók listázása kiválasztásra.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSelect$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSelect$Plain$Response(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListUserSelectsResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserSelectPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('InRoles', params.InRoles, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListUserSelectsResult>;
      })
    );
  }

  /**
   * Felhasználók listázása kiválasztásra.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSelect$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSelect$Plain(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListUserSelectsResult> {

    return this.userSelect$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListUserSelectsResult>) => r.body as ListUserSelectsResult)
    );
  }

  /**
   * Felhasználók listázása kiválasztásra.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSelect()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSelect$Response(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListUserSelectsResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserSelectPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('InRoles', params.InRoles, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListUserSelectsResult>;
      })
    );
  }

  /**
   * Felhasználók listázása kiválasztásra.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSelect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userSelect(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<ListUserSelectsResult> {

    return this.userSelect$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListUserSelectsResult>) => r.body as ListUserSelectsResult)
    );
  }

  /**
   * Path part for operation userListUserCards
   */
  static readonly UserListUserCardsPath = '/api/User/ListUserCards';

  /**
   * Felhasználói kártyák listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userListUserCards$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userListUserCards$Plain$Response(params?: {
    body?: ListUserCardsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListUserCardsResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserListUserCardsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListUserCardsResult>;
      })
    );
  }

  /**
   * Felhasználói kártyák listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userListUserCards$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userListUserCards$Plain(params?: {
    body?: ListUserCardsQuery
  },
  context?: HttpContext

): Observable<ListUserCardsResult> {

    return this.userListUserCards$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListUserCardsResult>) => r.body as ListUserCardsResult)
    );
  }

  /**
   * Felhasználói kártyák listázása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userListUserCards()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userListUserCards$Response(params?: {
    body?: ListUserCardsQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ListUserCardsResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserListUserCardsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ListUserCardsResult>;
      })
    );
  }

  /**
   * Felhasználói kártyák listázása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userListUserCards$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userListUserCards(params?: {
    body?: ListUserCardsQuery
  },
  context?: HttpContext

): Observable<ListUserCardsResult> {

    return this.userListUserCards$Response(params,context).pipe(
      map((r: StrictHttpResponse<ListUserCardsResult>) => r.body as ListUserCardsResult)
    );
  }

  /**
   * Path part for operation userUpdateUserCards
   */
  static readonly UserUpdateUserCardsPath = '/api/User/UpdateUserCards';

  /**
   * Felhasználói kártyák módosítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUpdateUserCards()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userUpdateUserCards$Response(params?: {
    body?: CreateOrUpdateUserCardsCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserUpdateUserCardsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználói kártyák módosítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUpdateUserCards$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userUpdateUserCards(params?: {
    body?: CreateOrUpdateUserCardsCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userUpdateUserCards$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userGetUserImage
   */
  static readonly UserGetUserImagePath = '/api/User/GetUserImage';

  /**
   * Felhasználói képek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserImage$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserImage$Plain$Response(params?: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
    Mini?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FileStream>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserGetUserImagePath, 'get');
    if (params) {
      rb.query('UserId', params.UserId, {});
      rb.query('PictureType', params.PictureType, {});
      rb.query('Thumbnail', params.Thumbnail, {});
      rb.query('UseDefaultIfNotExists', params.UseDefaultIfNotExists, {});
      rb.query('Mini', params.Mini, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStream>;
      })
    );
  }

  /**
   * Felhasználói képek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserImage$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserImage$Plain(params?: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
    Mini?: boolean;
  },
  context?: HttpContext

): Observable<FileStream> {

    return this.userGetUserImage$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FileStream>) => r.body as FileStream)
    );
  }

  /**
   * Felhasználói képek lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserImage$Response(params?: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
    Mini?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FileStream>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserGetUserImagePath, 'get');
    if (params) {
      rb.query('UserId', params.UserId, {});
      rb.query('PictureType', params.PictureType, {});
      rb.query('Thumbnail', params.Thumbnail, {});
      rb.query('UseDefaultIfNotExists', params.UseDefaultIfNotExists, {});
      rb.query('Mini', params.Mini, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FileStream>;
      })
    );
  }

  /**
   * Felhasználói képek lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserImage(params?: {
    UserId?: number;
    PictureType?: PictureType;
    Thumbnail?: boolean;
    UseDefaultIfNotExists?: boolean;
    Mini?: boolean;
  },
  context?: HttpContext

): Observable<FileStream> {

    return this.userGetUserImage$Response(params,context).pipe(
      map((r: StrictHttpResponse<FileStream>) => r.body as FileStream)
    );
  }

  /**
   * Path part for operation userBulkAssignLabel
   */
  static readonly UserBulkAssignLabelPath = '/api/User/BulkAssignLabel';

  /**
   * Csoportos címke hozzárendelés vagy törlés.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userBulkAssignLabel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userBulkAssignLabel$Response(params?: {
    body?: ModifyUserLabelCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserBulkAssignLabelPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Csoportos címke hozzárendelés vagy törlés.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userBulkAssignLabel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userBulkAssignLabel(params?: {
    body?: ModifyUserLabelCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userBulkAssignLabel$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userSendRegistrationLink
   */
  static readonly UserSendRegistrationLinkPath = '/api/User/SendRegistrationLink';

  /**
   * Regisztrációs link küldése emailben.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSendRegistrationLink()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSendRegistrationLink$Response(params?: {
    body?: SendRegistrationLinkCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserSendRegistrationLinkPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Regisztrációs link küldése emailben.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSendRegistrationLink$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userSendRegistrationLink(params?: {
    body?: SendRegistrationLinkCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userSendRegistrationLink$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation checkUniqueness
   */
  static readonly CheckUniquenessPath = '/api/User/CheckUniqueness';

  /**
   * Felhasználói adat egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUniqueness$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkUniqueness$Plain$Response(params?: {
    body?: CheckUniquenessQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.CheckUniquenessPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Felhasználói adat egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkUniqueness$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkUniqueness$Plain(params?: {
    body?: CheckUniquenessQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.checkUniqueness$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Felhasználói adat egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUniqueness()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkUniqueness$Response(params?: {
    body?: CheckUniquenessQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.CheckUniquenessPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Felhasználói adat egyediségének ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkUniqueness$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  checkUniqueness(params?: {
    body?: CheckUniquenessQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.checkUniqueness$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userGetUserState
   */
  static readonly UserGetUserStatePath = '/api/User/GetUserState';

  /**
   * Felhasználó állapotának lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserState$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserState$Plain$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserData>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserGetUserStatePath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserData>;
      })
    );
  }

  /**
   * Felhasználó állapotának lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserState$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserState$Plain(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<UserData> {

    return this.userGetUserState$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * Felhasználó állapotának lekérése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserState()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserState$Response(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<UserData>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserGetUserStatePath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserData>;
      })
    );
  }

  /**
   * Felhasználó állapotának lekérése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserState$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserState(params?: {
    Id?: number;
    TranslationLanguageCode?: string;
  },
  context?: HttpContext

): Observable<UserData> {

    return this.userGetUserState$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserData>) => r.body as UserData)
    );
  }

  /**
   * Path part for operation userCsv
   */
  static readonly UserCsvPath = '/api/User/Csv';

  /**
   * Felhasználói törzsadatok exportálása csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCsv$Plain$Response(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    ExcludeUserById?: number;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserCsvPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('InRoles', params.InRoles, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('ExcludeUserById', params.ExcludeUserById, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználói törzsadatok exportálása csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCsv$Plain(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    ExcludeUserById?: number;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Felhasználói törzsadatok exportálása csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCsv$Response(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    ExcludeUserById?: number;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserCsvPath, 'get');
    if (params) {
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('InRoles', params.InRoles, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('ExcludeUserById', params.ExcludeUserById, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználói törzsadatok exportálása csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userCsv(params?: {
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    ExcludeUserById?: number;
    RequestUserId?: number;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Path part for operation userCheckUserInactivability
   */
  static readonly UserCheckUserInactivabilityPath = '/api/User/CheckUserInactivability';

  /**
   * A megadott felhasználók inaktiválhatóságának ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCheckUserInactivability$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserInactivability$Plain$Response(params?: {
    body?: CheckUserInactivabilityQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CheckUserInactivabilityResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserCheckUserInactivabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckUserInactivabilityResult>;
      })
    );
  }

  /**
   * A megadott felhasználók inaktiválhatóságának ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCheckUserInactivability$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserInactivability$Plain(params?: {
    body?: CheckUserInactivabilityQuery
  },
  context?: HttpContext

): Observable<CheckUserInactivabilityResult> {

    return this.userCheckUserInactivability$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CheckUserInactivabilityResult>) => r.body as CheckUserInactivabilityResult)
    );
  }

  /**
   * A megadott felhasználók inaktiválhatóságának ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCheckUserInactivability()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserInactivability$Response(params?: {
    body?: CheckUserInactivabilityQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CheckUserInactivabilityResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserCheckUserInactivabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CheckUserInactivabilityResult>;
      })
    );
  }

  /**
   * A megadott felhasználók inaktiválhatóságának ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCheckUserInactivability$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserInactivability(params?: {
    body?: CheckUserInactivabilityQuery
  },
  context?: HttpContext

): Observable<CheckUserInactivabilityResult> {

    return this.userCheckUserInactivability$Response(params,context).pipe(
      map((r: StrictHttpResponse<CheckUserInactivabilityResult>) => r.body as CheckUserInactivabilityResult)
    );
  }

  /**
   * Path part for operation userCheckUserLabelRemovability
   */
  static readonly UserCheckUserLabelRemovabilityPath = '/api/User/CheckUserLabelRemovability';

  /**
   * A megadott felhasználói címke törölhetőség ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCheckUserLabelRemovability$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserLabelRemovability$Plain$Response(params?: {
    body?: CheckUserLabelRemovabilityQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserCheckUserLabelRemovabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * A megadott felhasználói címke törölhetőség ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCheckUserLabelRemovability$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserLabelRemovability$Plain(params?: {
    body?: CheckUserLabelRemovabilityQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.userCheckUserLabelRemovability$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * A megadott felhasználói címke törölhetőség ellenőrzése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCheckUserLabelRemovability()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserLabelRemovability$Response(params?: {
    body?: CheckUserLabelRemovabilityQuery
  },
  context?: HttpContext

): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserCheckUserLabelRemovabilityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * A megadott felhasználói címke törölhetőség ellenőrzése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCheckUserLabelRemovability$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userCheckUserLabelRemovability(params?: {
    body?: CheckUserLabelRemovabilityQuery
  },
  context?: HttpContext

): Observable<boolean> {

    return this.userCheckUserLabelRemovability$Response(params,context).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation userReactivateUser
   */
  static readonly UserReactivateUserPath = '/api/User/ReactivateUser';

  /**
   * Felhasználó visszaállítása.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userReactivateUser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userReactivateUser$Response(params?: {
    body?: ReactivateUserCommand
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserReactivateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Felhasználó visszaállítása.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userReactivateUser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  userReactivateUser(params?: {
    body?: ReactivateUserCommand
  },
  context?: HttpContext

): Observable<void> {

    return this.userReactivateUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userUserDataCsv
   */
  static readonly UserUserDataCsvPath = '/api/User/UserDataCsv';

  /**
   * Felhasználói azonosítók exportálása csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUserDataCsv$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  userUserDataCsv$Plain$Response(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    'SearchDetails.AdvertId'?: string;
    'SearchDetails.AgeMin'?: number;
    'SearchDetails.AgeMax'?: number;
    'SearchDetails.Recruiter'?: Array<EmployerData>;
    'SearchDetails.DateAfterInactive'?: string;
    'SearchDetails.Description'?: string;
    'SearchDetails.DrivingLicense'?: Array<DrivingLicenseData>;
    'SearchDetails.Employer'?: Array<EmployerData>;
    'SearchDetails.GenderFemale'?: boolean;
    'SearchDetails.GenderMale'?: boolean;
    'SearchDetails.Label'?: Array<LabelData>;
    'SearchDetails.AssignedLabel'?: boolean;
    'SearchDetails.LungFilter'?: string;
    'SearchDetails.OwnCar'?: boolean;
    'SearchDetails.OwnShoes'?: boolean;
    'SearchDetails.Referrer.Id'?: number;
    'SearchDetails.Referrer.State'?: UserState;
    'SearchDetails.Referrer.Password'?: string;
    'SearchDetails.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.Referrer.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.Referrer.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.Referrer.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.Referrer.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.Referrer.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.Referrer.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.Referrer.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.Referrer.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.Referrer.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.Referrer.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.Referrer.BaseData.Nationality'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Id'?: number;
    'SearchDetails.Referrer.BaseData.IDType.Name'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.Referrer.BaseData.IDNumber'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Employer.Name'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Employer.Email'?: string;
    'SearchDetails.Referrer.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.EmployerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Id'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Name'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Recruiter.Email'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.Referrer.BaseData.AdvertisementId'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.State'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.Password'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ReferrerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.Referrer.BaseData.ProfilePicture'?: string;
    'SearchDetails.Referrer.BaseData.LastActivity'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUserId'?: number;
    'SearchDetails.Referrer.BaseData.LastStateChange'?: string;
    'SearchDetails.Referrer.BaseData.LastStateReactivation'?: string;
    'SearchDetails.Referrer.BaseData.TestScore'?: number;
    'SearchDetails.Referrer.BaseData.EmailVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.Referrer.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.NextStateDate'?: string;
    'SearchDetails.Relationship'?: Array<EmployerRelationshipData>;
    'SearchDetails.UserState'?: Array<UserStateData>;
    'SearchDetails.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.ContactUser.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.ContactUser.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.ContactUser.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.ContactUser.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.ContactUser.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.ContactUser.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.ContactUser.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.ContactUser.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.ContactUser.BaseData.Nationality'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.IDType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.ContactUser.BaseData.IDNumber'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Employer.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Employer.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.EmployerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Recruiter.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.ContactUser.BaseData.AdvertisementId'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.Password'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ReferrerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.ContactUser.BaseData.ProfilePicture'?: string;
    'SearchDetails.ContactUser.BaseData.LastActivity'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUserId'?: number;
    'SearchDetails.ContactUser.BaseData.LastStateChange'?: string;
    'SearchDetails.ContactUser.BaseData.LastStateReactivation'?: string;
    'SearchDetails.ContactUser.BaseData.TestScore'?: number;
    'SearchDetails.ContactUser.BaseData.EmailVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.ContactUser.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.NextStateDate'?: string;
    'SearchDetails.FullNameOrMcode'?: string;
    'SearchDetails.SchoolType'?: Array<SchoolTypeData>;
    'SearchDetails.Shifts'?: Array<ShiftData>;
    'SearchDetails.PrivateDescription'?: string;
    'SearchDetails.Role'?: Array<RoleData>;
    ExcludeUserById?: number;
    RequestUserId?: number;
    GetCSV?: boolean;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserUserDataCsvPath, 'get');
    if (params) {
      rb.query('ActiveOnly', params.ActiveOnly, {});
      rb.query('IncludeBuiltIn', params.IncludeBuiltIn, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('InRoles', params.InRoles, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('SearchDetails.AdvertId', params['SearchDetails.AdvertId'], {});
      rb.query('SearchDetails.AgeMin', params['SearchDetails.AgeMin'], {});
      rb.query('SearchDetails.AgeMax', params['SearchDetails.AgeMax'], {});
      rb.query('SearchDetails.Recruiter', params['SearchDetails.Recruiter'], {});
      rb.query('SearchDetails.DateAfterInactive', params['SearchDetails.DateAfterInactive'], {});
      rb.query('SearchDetails.Description', params['SearchDetails.Description'], {});
      rb.query('SearchDetails.DrivingLicense', params['SearchDetails.DrivingLicense'], {});
      rb.query('SearchDetails.Employer', params['SearchDetails.Employer'], {});
      rb.query('SearchDetails.GenderFemale', params['SearchDetails.GenderFemale'], {});
      rb.query('SearchDetails.GenderMale', params['SearchDetails.GenderMale'], {});
      rb.query('SearchDetails.Label', params['SearchDetails.Label'], {});
      rb.query('SearchDetails.AssignedLabel', params['SearchDetails.AssignedLabel'], {});
      rb.query('SearchDetails.LungFilter', params['SearchDetails.LungFilter'], {});
      rb.query('SearchDetails.OwnCar', params['SearchDetails.OwnCar'], {});
      rb.query('SearchDetails.OwnShoes', params['SearchDetails.OwnShoes'], {});
      rb.query('SearchDetails.Referrer.Id', params['SearchDetails.Referrer.Id'], {});
      rb.query('SearchDetails.Referrer.State', params['SearchDetails.Referrer.State'], {});
      rb.query('SearchDetails.Referrer.Password', params['SearchDetails.Referrer.Password'], {});
      rb.query('SearchDetails.Referrer.Permissions.GlobalPermissions', params['SearchDetails.Referrer.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.RoleName', params['SearchDetails.Referrer.RoleName'], {});
      rb.query('SearchDetails.Referrer.LocalizedRoleName', params['SearchDetails.Referrer.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.Role.Id', params['SearchDetails.Referrer.Role.Id'], {});
      rb.query('SearchDetails.Referrer.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.Role.RoleName', params['SearchDetails.Referrer.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.Role.Description', params['SearchDetails.Referrer.Role.Description'], {});
      rb.query('SearchDetails.Referrer.Role.Order', params['SearchDetails.Referrer.Role.Order'], {});
      rb.query('SearchDetails.Referrer.Role.LocalizedRoleName', params['SearchDetails.Referrer.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.FullName', params['SearchDetails.Referrer.BaseData.FullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.MCode', params['SearchDetails.Referrer.BaseData.MCode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Surname', params['SearchDetails.Referrer.BaseData.Surname'], {});
      rb.query('SearchDetails.Referrer.BaseData.Firstname', params['SearchDetails.Referrer.BaseData.Firstname'], {});
      rb.query('SearchDetails.Referrer.BaseData.Firstname2', params['SearchDetails.Referrer.BaseData.Firstname2'], {});
      rb.query('SearchDetails.Referrer.BaseData.TaxNumber', params['SearchDetails.Referrer.BaseData.TaxNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.PhoneNumber', params['SearchDetails.Referrer.BaseData.PhoneNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.PhoneNumberCountryCode', params['SearchDetails.Referrer.BaseData.PhoneNumberCountryCode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Email', params['SearchDetails.Referrer.BaseData.Email'], {});
      rb.query('SearchDetails.Referrer.BaseData.Gender', params['SearchDetails.Referrer.BaseData.Gender'], {});
      rb.query('SearchDetails.Referrer.BaseData.SocialSecurityNumber', params['SearchDetails.Referrer.BaseData.SocialSecurityNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.BankAccount', params['SearchDetails.Referrer.BaseData.BankAccount'], {});
      rb.query('SearchDetails.Referrer.BaseData.Nationality', params['SearchDetails.Referrer.BaseData.Nationality'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDType.Id', params['SearchDetails.Referrer.BaseData.IDType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDType.Name', params['SearchDetails.Referrer.BaseData.IDType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDType.Active', params['SearchDetails.Referrer.BaseData.IDType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDNumber', params['SearchDetails.Referrer.BaseData.IDNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Id', params['SearchDetails.Referrer.BaseData.Employer.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Name', params['SearchDetails.Referrer.BaseData.Employer.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Active', params['SearchDetails.Referrer.BaseData.Employer.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Email', params['SearchDetails.Referrer.BaseData.Employer.Email'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.EmployerRelationships', params['SearchDetails.Referrer.BaseData.Employer.EmployerRelationships'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerId', params['SearchDetails.Referrer.BaseData.EmployerId'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationship.Id', params['SearchDetails.Referrer.BaseData.EmployerRelationship.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationship.Name', params['SearchDetails.Referrer.BaseData.EmployerRelationship.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationship.Active', params['SearchDetails.Referrer.BaseData.EmployerRelationship.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationshipId', params['SearchDetails.Referrer.BaseData.EmployerRelationshipId'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Id', params['SearchDetails.Referrer.BaseData.Recruiter.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Name', params['SearchDetails.Referrer.BaseData.Recruiter.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Active', params['SearchDetails.Referrer.BaseData.Recruiter.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Email', params['SearchDetails.Referrer.BaseData.Recruiter.Email'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships', params['SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships'], {});
      rb.query('SearchDetails.Referrer.BaseData.RecruiterId', params['SearchDetails.Referrer.BaseData.RecruiterId'], {});
      rb.query('SearchDetails.Referrer.BaseData.AdvertisementId', params['SearchDetails.Referrer.BaseData.AdvertisementId'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Id', params['SearchDetails.Referrer.BaseData.Referrer.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.State', params['SearchDetails.Referrer.BaseData.Referrer.State'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Password', params['SearchDetails.Referrer.BaseData.Referrer.Password'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.RoleName', params['SearchDetails.Referrer.BaseData.Referrer.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Id', params['SearchDetails.Referrer.BaseData.Referrer.Role.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.RoleName', params['SearchDetails.Referrer.BaseData.Referrer.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Description', params['SearchDetails.Referrer.BaseData.Referrer.Role.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Order', params['SearchDetails.Referrer.BaseData.Referrer.Role.Order'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BaseData', params['SearchDetails.Referrer.BaseData.Referrer.BaseData'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.Country', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.City', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.Address', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.LocalizedState', params['SearchDetails.Referrer.BaseData.Referrer.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.RegistrationLink', params['SearchDetails.Referrer.BaseData.Referrer.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent', params['SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists', params['SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.PrivateDescription', params['SearchDetails.Referrer.BaseData.Referrer.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation', params['SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateDate', params['SearchDetails.Referrer.BaseData.Referrer.NextStateDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ReferrerId', params['SearchDetails.Referrer.BaseData.ReferrerId'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUserId', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUserId'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationTime', params['SearchDetails.Referrer.BaseData.EmployerRegistrationTime'], {});
      rb.query('SearchDetails.Referrer.BaseData.ProfilePicture', params['SearchDetails.Referrer.BaseData.ProfilePicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.LastActivity', params['SearchDetails.Referrer.BaseData.LastActivity'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Id', params['SearchDetails.Referrer.BaseData.ContactUser.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.State', params['SearchDetails.Referrer.BaseData.ContactUser.State'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Password', params['SearchDetails.Referrer.BaseData.ContactUser.Password'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.RoleName', params['SearchDetails.Referrer.BaseData.ContactUser.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Id', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName', params['SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Description', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Order', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Order'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BaseData', params['SearchDetails.Referrer.BaseData.ContactUser.BaseData'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.City', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.LocalizedState', params['SearchDetails.Referrer.BaseData.ContactUser.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink', params['SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent', params['SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists', params['SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription', params['SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation', params['SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateDate', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUserId', params['SearchDetails.Referrer.BaseData.ContactUserId'], {});
      rb.query('SearchDetails.Referrer.BaseData.LastStateChange', params['SearchDetails.Referrer.BaseData.LastStateChange'], {});
      rb.query('SearchDetails.Referrer.BaseData.LastStateReactivation', params['SearchDetails.Referrer.BaseData.LastStateReactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.TestScore', params['SearchDetails.Referrer.BaseData.TestScore'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmailVerified', params['SearchDetails.Referrer.BaseData.EmailVerified'], {});
      rb.query('SearchDetails.Referrer.BaseData.PhoneNumberVerified', params['SearchDetails.Referrer.BaseData.PhoneNumberVerified'], {});
      rb.query('SearchDetails.Referrer.BaseData.PaymentSchedule.Id', params['SearchDetails.Referrer.BaseData.PaymentSchedule.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.PaymentSchedule.Name', params['SearchDetails.Referrer.BaseData.PaymentSchedule.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.PaymentSchedule.Active', params['SearchDetails.Referrer.BaseData.PaymentSchedule.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.WageCodeUser', params['SearchDetails.Referrer.BaseData.WageCodeUser'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthFullName', params['SearchDetails.Referrer.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthSurname', params['SearchDetails.Referrer.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthFirstName', params['SearchDetails.Referrer.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthCountry', params['SearchDetails.Referrer.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthPlace', params['SearchDetails.Referrer.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthDate', params['SearchDetails.Referrer.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersFullName', params['SearchDetails.Referrer.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersSurName', params['SearchDetails.Referrer.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersFirstName', params['SearchDetails.Referrer.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.AddressData.Country', params['SearchDetails.Referrer.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.AddressData.Postcode', params['SearchDetails.Referrer.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.AddressData.City', params['SearchDetails.Referrer.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.AddressData.Address', params['SearchDetails.Referrer.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempCountry', params['SearchDetails.Referrer.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempPostcode', params['SearchDetails.Referrer.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempCity', params['SearchDetails.Referrer.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempAddress', params['SearchDetails.Referrer.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.Shifts', params['SearchDetails.Referrer.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.ShoeSize', params['SearchDetails.Referrer.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.OwnCar', params['SearchDetails.Referrer.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.OwnShoe', params['SearchDetails.Referrer.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.Description', params['SearchDetails.Referrer.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.Labels', params['SearchDetails.Referrer.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.LocalizedState', params['SearchDetails.Referrer.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.RegistrationLink', params['SearchDetails.Referrer.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextState', params['SearchDetails.Referrer.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.RegistrationLinkSent', params['SearchDetails.Referrer.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.ProfilePictureExists', params['SearchDetails.Referrer.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.PrivateDescription', params['SearchDetails.Referrer.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.StateBeforeInactivation', params['SearchDetails.Referrer.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.NextStateDate', params['SearchDetails.Referrer.NextStateDate'], {});
      rb.query('SearchDetails.Relationship', params['SearchDetails.Relationship'], {});
      rb.query('SearchDetails.UserState', params['SearchDetails.UserState'], {});
      rb.query('SearchDetails.ContactUser.Id', params['SearchDetails.ContactUser.Id'], {});
      rb.query('SearchDetails.ContactUser.State', params['SearchDetails.ContactUser.State'], {});
      rb.query('SearchDetails.ContactUser.Password', params['SearchDetails.ContactUser.Password'], {});
      rb.query('SearchDetails.ContactUser.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.RoleName', params['SearchDetails.ContactUser.RoleName'], {});
      rb.query('SearchDetails.ContactUser.LocalizedRoleName', params['SearchDetails.ContactUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.Role.Id', params['SearchDetails.ContactUser.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.Role.RoleName', params['SearchDetails.ContactUser.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.Role.Description', params['SearchDetails.ContactUser.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.Role.Order', params['SearchDetails.ContactUser.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.Role.LocalizedRoleName', params['SearchDetails.ContactUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.FullName', params['SearchDetails.ContactUser.BaseData.FullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.MCode', params['SearchDetails.ContactUser.BaseData.MCode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Surname', params['SearchDetails.ContactUser.BaseData.Surname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Firstname', params['SearchDetails.ContactUser.BaseData.Firstname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Firstname2', params['SearchDetails.ContactUser.BaseData.Firstname2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.TaxNumber', params['SearchDetails.ContactUser.BaseData.TaxNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PhoneNumber', params['SearchDetails.ContactUser.BaseData.PhoneNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode', params['SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Email', params['SearchDetails.ContactUser.BaseData.Email'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Gender', params['SearchDetails.ContactUser.BaseData.Gender'], {});
      rb.query('SearchDetails.ContactUser.BaseData.SocialSecurityNumber', params['SearchDetails.ContactUser.BaseData.SocialSecurityNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.BankAccount', params['SearchDetails.ContactUser.BaseData.BankAccount'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Nationality', params['SearchDetails.ContactUser.BaseData.Nationality'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDType.Id', params['SearchDetails.ContactUser.BaseData.IDType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDType.Name', params['SearchDetails.ContactUser.BaseData.IDType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDType.Active', params['SearchDetails.ContactUser.BaseData.IDType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDNumber', params['SearchDetails.ContactUser.BaseData.IDNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Id', params['SearchDetails.ContactUser.BaseData.Employer.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Name', params['SearchDetails.ContactUser.BaseData.Employer.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Active', params['SearchDetails.ContactUser.BaseData.Employer.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Email', params['SearchDetails.ContactUser.BaseData.Employer.Email'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships', params['SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerId', params['SearchDetails.ContactUser.BaseData.EmployerId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationship.Id', params['SearchDetails.ContactUser.BaseData.EmployerRelationship.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationship.Name', params['SearchDetails.ContactUser.BaseData.EmployerRelationship.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationship.Active', params['SearchDetails.ContactUser.BaseData.EmployerRelationship.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationshipId', params['SearchDetails.ContactUser.BaseData.EmployerRelationshipId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Id', params['SearchDetails.ContactUser.BaseData.Recruiter.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Name', params['SearchDetails.ContactUser.BaseData.Recruiter.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Active', params['SearchDetails.ContactUser.BaseData.Recruiter.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Email', params['SearchDetails.ContactUser.BaseData.Recruiter.Email'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships', params['SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships'], {});
      rb.query('SearchDetails.ContactUser.BaseData.RecruiterId', params['SearchDetails.ContactUser.BaseData.RecruiterId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.AdvertisementId', params['SearchDetails.ContactUser.BaseData.AdvertisementId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Id', params['SearchDetails.ContactUser.BaseData.Referrer.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.State', params['SearchDetails.ContactUser.BaseData.Referrer.State'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Password', params['SearchDetails.ContactUser.BaseData.Referrer.Password'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.RoleName', params['SearchDetails.ContactUser.BaseData.Referrer.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Id', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName', params['SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Description', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Order', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BaseData', params['SearchDetails.ContactUser.BaseData.Referrer.BaseData'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.City', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.LocalizedState', params['SearchDetails.ContactUser.BaseData.Referrer.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink', params['SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent', params['SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists', params['SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription', params['SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation', params['SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateDate', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ReferrerId', params['SearchDetails.ContactUser.BaseData.ReferrerId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationTime', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationTime'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ProfilePicture', params['SearchDetails.ContactUser.BaseData.ProfilePicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.LastActivity', params['SearchDetails.ContactUser.BaseData.LastActivity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.State', params['SearchDetails.ContactUser.BaseData.ContactUser.State'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Password', params['SearchDetails.ContactUser.BaseData.ContactUser.Password'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.RoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Description', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Order', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BaseData', params['SearchDetails.ContactUser.BaseData.ContactUser.BaseData'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState', params['SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink', params['SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent', params['SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists', params['SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription', params['SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation', params['SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUserId', params['SearchDetails.ContactUser.BaseData.ContactUserId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.LastStateChange', params['SearchDetails.ContactUser.BaseData.LastStateChange'], {});
      rb.query('SearchDetails.ContactUser.BaseData.LastStateReactivation', params['SearchDetails.ContactUser.BaseData.LastStateReactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.TestScore', params['SearchDetails.ContactUser.BaseData.TestScore'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmailVerified', params['SearchDetails.ContactUser.BaseData.EmailVerified'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PhoneNumberVerified', params['SearchDetails.ContactUser.BaseData.PhoneNumberVerified'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PaymentSchedule.Id', params['SearchDetails.ContactUser.BaseData.PaymentSchedule.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PaymentSchedule.Name', params['SearchDetails.ContactUser.BaseData.PaymentSchedule.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PaymentSchedule.Active', params['SearchDetails.ContactUser.BaseData.PaymentSchedule.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.WageCodeUser', params['SearchDetails.ContactUser.BaseData.WageCodeUser'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthFullName', params['SearchDetails.ContactUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthSurname', params['SearchDetails.ContactUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthCountry', params['SearchDetails.ContactUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthPlace', params['SearchDetails.ContactUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthDate', params['SearchDetails.ContactUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersFullName', params['SearchDetails.ContactUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersSurName', params['SearchDetails.ContactUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.AddressData.Country', params['SearchDetails.ContactUser.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.AddressData.Postcode', params['SearchDetails.ContactUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.AddressData.City', params['SearchDetails.ContactUser.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.AddressData.Address', params['SearchDetails.ContactUser.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempCountry', params['SearchDetails.ContactUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempPostcode', params['SearchDetails.ContactUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempCity', params['SearchDetails.ContactUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempAddress', params['SearchDetails.ContactUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.Shifts', params['SearchDetails.ContactUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.OwnCar', params['SearchDetails.ContactUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.Description', params['SearchDetails.ContactUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.Labels', params['SearchDetails.ContactUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.LocalizedState', params['SearchDetails.ContactUser.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.RegistrationLink', params['SearchDetails.ContactUser.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextState', params['SearchDetails.ContactUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.RegistrationLinkSent', params['SearchDetails.ContactUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.ProfilePictureExists', params['SearchDetails.ContactUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.PrivateDescription', params['SearchDetails.ContactUser.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.StateBeforeInactivation', params['SearchDetails.ContactUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.NextStateDate', params['SearchDetails.ContactUser.NextStateDate'], {});
      rb.query('SearchDetails.FullNameOrMcode', params['SearchDetails.FullNameOrMcode'], {});
      rb.query('SearchDetails.SchoolType', params['SearchDetails.SchoolType'], {});
      rb.query('SearchDetails.Shifts', params['SearchDetails.Shifts'], {});
      rb.query('SearchDetails.PrivateDescription', params['SearchDetails.PrivateDescription'], {});
      rb.query('SearchDetails.Role', params['SearchDetails.Role'], {});
      rb.query('ExcludeUserById', params.ExcludeUserById, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('GetCSV', params.GetCSV, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználói azonosítók exportálása csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUserDataCsv$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userUserDataCsv$Plain(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    'SearchDetails.AdvertId'?: string;
    'SearchDetails.AgeMin'?: number;
    'SearchDetails.AgeMax'?: number;
    'SearchDetails.Recruiter'?: Array<EmployerData>;
    'SearchDetails.DateAfterInactive'?: string;
    'SearchDetails.Description'?: string;
    'SearchDetails.DrivingLicense'?: Array<DrivingLicenseData>;
    'SearchDetails.Employer'?: Array<EmployerData>;
    'SearchDetails.GenderFemale'?: boolean;
    'SearchDetails.GenderMale'?: boolean;
    'SearchDetails.Label'?: Array<LabelData>;
    'SearchDetails.AssignedLabel'?: boolean;
    'SearchDetails.LungFilter'?: string;
    'SearchDetails.OwnCar'?: boolean;
    'SearchDetails.OwnShoes'?: boolean;
    'SearchDetails.Referrer.Id'?: number;
    'SearchDetails.Referrer.State'?: UserState;
    'SearchDetails.Referrer.Password'?: string;
    'SearchDetails.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.Referrer.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.Referrer.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.Referrer.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.Referrer.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.Referrer.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.Referrer.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.Referrer.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.Referrer.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.Referrer.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.Referrer.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.Referrer.BaseData.Nationality'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Id'?: number;
    'SearchDetails.Referrer.BaseData.IDType.Name'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.Referrer.BaseData.IDNumber'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Employer.Name'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Employer.Email'?: string;
    'SearchDetails.Referrer.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.EmployerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Id'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Name'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Recruiter.Email'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.Referrer.BaseData.AdvertisementId'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.State'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.Password'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ReferrerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.Referrer.BaseData.ProfilePicture'?: string;
    'SearchDetails.Referrer.BaseData.LastActivity'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUserId'?: number;
    'SearchDetails.Referrer.BaseData.LastStateChange'?: string;
    'SearchDetails.Referrer.BaseData.LastStateReactivation'?: string;
    'SearchDetails.Referrer.BaseData.TestScore'?: number;
    'SearchDetails.Referrer.BaseData.EmailVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.Referrer.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.NextStateDate'?: string;
    'SearchDetails.Relationship'?: Array<EmployerRelationshipData>;
    'SearchDetails.UserState'?: Array<UserStateData>;
    'SearchDetails.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.ContactUser.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.ContactUser.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.ContactUser.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.ContactUser.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.ContactUser.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.ContactUser.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.ContactUser.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.ContactUser.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.ContactUser.BaseData.Nationality'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.IDType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.ContactUser.BaseData.IDNumber'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Employer.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Employer.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.EmployerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Recruiter.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.ContactUser.BaseData.AdvertisementId'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.Password'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ReferrerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.ContactUser.BaseData.ProfilePicture'?: string;
    'SearchDetails.ContactUser.BaseData.LastActivity'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUserId'?: number;
    'SearchDetails.ContactUser.BaseData.LastStateChange'?: string;
    'SearchDetails.ContactUser.BaseData.LastStateReactivation'?: string;
    'SearchDetails.ContactUser.BaseData.TestScore'?: number;
    'SearchDetails.ContactUser.BaseData.EmailVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.ContactUser.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.NextStateDate'?: string;
    'SearchDetails.FullNameOrMcode'?: string;
    'SearchDetails.SchoolType'?: Array<SchoolTypeData>;
    'SearchDetails.Shifts'?: Array<ShiftData>;
    'SearchDetails.PrivateDescription'?: string;
    'SearchDetails.Role'?: Array<RoleData>;
    ExcludeUserById?: number;
    RequestUserId?: number;
    GetCSV?: boolean;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userUserDataCsv$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

  /**
   * Felhasználói azonosítók exportálása csv fájlba.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUserDataCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  userUserDataCsv$Response(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    'SearchDetails.AdvertId'?: string;
    'SearchDetails.AgeMin'?: number;
    'SearchDetails.AgeMax'?: number;
    'SearchDetails.Recruiter'?: Array<EmployerData>;
    'SearchDetails.DateAfterInactive'?: string;
    'SearchDetails.Description'?: string;
    'SearchDetails.DrivingLicense'?: Array<DrivingLicenseData>;
    'SearchDetails.Employer'?: Array<EmployerData>;
    'SearchDetails.GenderFemale'?: boolean;
    'SearchDetails.GenderMale'?: boolean;
    'SearchDetails.Label'?: Array<LabelData>;
    'SearchDetails.AssignedLabel'?: boolean;
    'SearchDetails.LungFilter'?: string;
    'SearchDetails.OwnCar'?: boolean;
    'SearchDetails.OwnShoes'?: boolean;
    'SearchDetails.Referrer.Id'?: number;
    'SearchDetails.Referrer.State'?: UserState;
    'SearchDetails.Referrer.Password'?: string;
    'SearchDetails.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.Referrer.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.Referrer.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.Referrer.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.Referrer.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.Referrer.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.Referrer.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.Referrer.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.Referrer.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.Referrer.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.Referrer.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.Referrer.BaseData.Nationality'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Id'?: number;
    'SearchDetails.Referrer.BaseData.IDType.Name'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.Referrer.BaseData.IDNumber'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Employer.Name'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Employer.Email'?: string;
    'SearchDetails.Referrer.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.EmployerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Id'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Name'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Recruiter.Email'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.Referrer.BaseData.AdvertisementId'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.State'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.Password'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ReferrerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.Referrer.BaseData.ProfilePicture'?: string;
    'SearchDetails.Referrer.BaseData.LastActivity'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUserId'?: number;
    'SearchDetails.Referrer.BaseData.LastStateChange'?: string;
    'SearchDetails.Referrer.BaseData.LastStateReactivation'?: string;
    'SearchDetails.Referrer.BaseData.TestScore'?: number;
    'SearchDetails.Referrer.BaseData.EmailVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.Referrer.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.NextStateDate'?: string;
    'SearchDetails.Relationship'?: Array<EmployerRelationshipData>;
    'SearchDetails.UserState'?: Array<UserStateData>;
    'SearchDetails.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.ContactUser.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.ContactUser.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.ContactUser.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.ContactUser.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.ContactUser.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.ContactUser.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.ContactUser.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.ContactUser.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.ContactUser.BaseData.Nationality'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.IDType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.ContactUser.BaseData.IDNumber'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Employer.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Employer.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.EmployerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Recruiter.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.ContactUser.BaseData.AdvertisementId'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.Password'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ReferrerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.ContactUser.BaseData.ProfilePicture'?: string;
    'SearchDetails.ContactUser.BaseData.LastActivity'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUserId'?: number;
    'SearchDetails.ContactUser.BaseData.LastStateChange'?: string;
    'SearchDetails.ContactUser.BaseData.LastStateReactivation'?: string;
    'SearchDetails.ContactUser.BaseData.TestScore'?: number;
    'SearchDetails.ContactUser.BaseData.EmailVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.ContactUser.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.NextStateDate'?: string;
    'SearchDetails.FullNameOrMcode'?: string;
    'SearchDetails.SchoolType'?: Array<SchoolTypeData>;
    'SearchDetails.Shifts'?: Array<ShiftData>;
    'SearchDetails.PrivateDescription'?: string;
    'SearchDetails.Role'?: Array<RoleData>;
    ExcludeUserById?: number;
    RequestUserId?: number;
    GetCSV?: boolean;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Blob>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.UserUserDataCsvPath, 'get');
    if (params) {
      rb.query('ActiveOnly', params.ActiveOnly, {});
      rb.query('IncludeBuiltIn', params.IncludeBuiltIn, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('InRoles', params.InRoles, {});
      rb.query('TranslationLanguageCode', params.TranslationLanguageCode, {});
      rb.query('SearchDetails.AdvertId', params['SearchDetails.AdvertId'], {});
      rb.query('SearchDetails.AgeMin', params['SearchDetails.AgeMin'], {});
      rb.query('SearchDetails.AgeMax', params['SearchDetails.AgeMax'], {});
      rb.query('SearchDetails.Recruiter', params['SearchDetails.Recruiter'], {});
      rb.query('SearchDetails.DateAfterInactive', params['SearchDetails.DateAfterInactive'], {});
      rb.query('SearchDetails.Description', params['SearchDetails.Description'], {});
      rb.query('SearchDetails.DrivingLicense', params['SearchDetails.DrivingLicense'], {});
      rb.query('SearchDetails.Employer', params['SearchDetails.Employer'], {});
      rb.query('SearchDetails.GenderFemale', params['SearchDetails.GenderFemale'], {});
      rb.query('SearchDetails.GenderMale', params['SearchDetails.GenderMale'], {});
      rb.query('SearchDetails.Label', params['SearchDetails.Label'], {});
      rb.query('SearchDetails.AssignedLabel', params['SearchDetails.AssignedLabel'], {});
      rb.query('SearchDetails.LungFilter', params['SearchDetails.LungFilter'], {});
      rb.query('SearchDetails.OwnCar', params['SearchDetails.OwnCar'], {});
      rb.query('SearchDetails.OwnShoes', params['SearchDetails.OwnShoes'], {});
      rb.query('SearchDetails.Referrer.Id', params['SearchDetails.Referrer.Id'], {});
      rb.query('SearchDetails.Referrer.State', params['SearchDetails.Referrer.State'], {});
      rb.query('SearchDetails.Referrer.Password', params['SearchDetails.Referrer.Password'], {});
      rb.query('SearchDetails.Referrer.Permissions.GlobalPermissions', params['SearchDetails.Referrer.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.RoleName', params['SearchDetails.Referrer.RoleName'], {});
      rb.query('SearchDetails.Referrer.LocalizedRoleName', params['SearchDetails.Referrer.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.Role.Id', params['SearchDetails.Referrer.Role.Id'], {});
      rb.query('SearchDetails.Referrer.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.Role.RoleName', params['SearchDetails.Referrer.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.Role.Description', params['SearchDetails.Referrer.Role.Description'], {});
      rb.query('SearchDetails.Referrer.Role.Order', params['SearchDetails.Referrer.Role.Order'], {});
      rb.query('SearchDetails.Referrer.Role.LocalizedRoleName', params['SearchDetails.Referrer.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.FullName', params['SearchDetails.Referrer.BaseData.FullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.MCode', params['SearchDetails.Referrer.BaseData.MCode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Surname', params['SearchDetails.Referrer.BaseData.Surname'], {});
      rb.query('SearchDetails.Referrer.BaseData.Firstname', params['SearchDetails.Referrer.BaseData.Firstname'], {});
      rb.query('SearchDetails.Referrer.BaseData.Firstname2', params['SearchDetails.Referrer.BaseData.Firstname2'], {});
      rb.query('SearchDetails.Referrer.BaseData.TaxNumber', params['SearchDetails.Referrer.BaseData.TaxNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.PhoneNumber', params['SearchDetails.Referrer.BaseData.PhoneNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.PhoneNumberCountryCode', params['SearchDetails.Referrer.BaseData.PhoneNumberCountryCode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Email', params['SearchDetails.Referrer.BaseData.Email'], {});
      rb.query('SearchDetails.Referrer.BaseData.Gender', params['SearchDetails.Referrer.BaseData.Gender'], {});
      rb.query('SearchDetails.Referrer.BaseData.SocialSecurityNumber', params['SearchDetails.Referrer.BaseData.SocialSecurityNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.BankAccount', params['SearchDetails.Referrer.BaseData.BankAccount'], {});
      rb.query('SearchDetails.Referrer.BaseData.Nationality', params['SearchDetails.Referrer.BaseData.Nationality'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDType.Id', params['SearchDetails.Referrer.BaseData.IDType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDType.Name', params['SearchDetails.Referrer.BaseData.IDType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDType.Active', params['SearchDetails.Referrer.BaseData.IDType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.IDNumber', params['SearchDetails.Referrer.BaseData.IDNumber'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Id', params['SearchDetails.Referrer.BaseData.Employer.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Name', params['SearchDetails.Referrer.BaseData.Employer.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Active', params['SearchDetails.Referrer.BaseData.Employer.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.Email', params['SearchDetails.Referrer.BaseData.Employer.Email'], {});
      rb.query('SearchDetails.Referrer.BaseData.Employer.EmployerRelationships', params['SearchDetails.Referrer.BaseData.Employer.EmployerRelationships'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerId', params['SearchDetails.Referrer.BaseData.EmployerId'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationship.Id', params['SearchDetails.Referrer.BaseData.EmployerRelationship.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationship.Name', params['SearchDetails.Referrer.BaseData.EmployerRelationship.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationship.Active', params['SearchDetails.Referrer.BaseData.EmployerRelationship.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRelationshipId', params['SearchDetails.Referrer.BaseData.EmployerRelationshipId'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Id', params['SearchDetails.Referrer.BaseData.Recruiter.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Name', params['SearchDetails.Referrer.BaseData.Recruiter.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Active', params['SearchDetails.Referrer.BaseData.Recruiter.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.Email', params['SearchDetails.Referrer.BaseData.Recruiter.Email'], {});
      rb.query('SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships', params['SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships'], {});
      rb.query('SearchDetails.Referrer.BaseData.RecruiterId', params['SearchDetails.Referrer.BaseData.RecruiterId'], {});
      rb.query('SearchDetails.Referrer.BaseData.AdvertisementId', params['SearchDetails.Referrer.BaseData.AdvertisementId'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Id', params['SearchDetails.Referrer.BaseData.Referrer.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.State', params['SearchDetails.Referrer.BaseData.Referrer.State'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Password', params['SearchDetails.Referrer.BaseData.Referrer.Password'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.RoleName', params['SearchDetails.Referrer.BaseData.Referrer.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Id', params['SearchDetails.Referrer.BaseData.Referrer.Role.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.RoleName', params['SearchDetails.Referrer.BaseData.Referrer.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Description', params['SearchDetails.Referrer.BaseData.Referrer.Role.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.Order', params['SearchDetails.Referrer.BaseData.Referrer.Role.Order'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BaseData', params['SearchDetails.Referrer.BaseData.Referrer.BaseData'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.Country', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.City', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.Address', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress', params['SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.LocalizedState', params['SearchDetails.Referrer.BaseData.Referrer.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.RegistrationLink', params['SearchDetails.Referrer.BaseData.Referrer.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent', params['SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists', params['SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.PrivateDescription', params['SearchDetails.Referrer.BaseData.Referrer.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation', params['SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.Referrer.NextStateDate', params['SearchDetails.Referrer.BaseData.Referrer.NextStateDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ReferrerId', params['SearchDetails.Referrer.BaseData.ReferrerId'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationUserId', params['SearchDetails.Referrer.BaseData.EmployerRegistrationUserId'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmployerRegistrationTime', params['SearchDetails.Referrer.BaseData.EmployerRegistrationTime'], {});
      rb.query('SearchDetails.Referrer.BaseData.ProfilePicture', params['SearchDetails.Referrer.BaseData.ProfilePicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.LastActivity', params['SearchDetails.Referrer.BaseData.LastActivity'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Id', params['SearchDetails.Referrer.BaseData.ContactUser.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.State', params['SearchDetails.Referrer.BaseData.ContactUser.State'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Password', params['SearchDetails.Referrer.BaseData.ContactUser.Password'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.RoleName', params['SearchDetails.Referrer.BaseData.ContactUser.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Id', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName', params['SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Description', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.Order', params['SearchDetails.Referrer.BaseData.ContactUser.Role.Order'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName', params['SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BaseData', params['SearchDetails.Referrer.BaseData.ContactUser.BaseData'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.City', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress', params['SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.LocalizedState', params['SearchDetails.Referrer.BaseData.ContactUser.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink', params['SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent', params['SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists', params['SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription', params['SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation', params['SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUser.NextStateDate', params['SearchDetails.Referrer.BaseData.ContactUser.NextStateDate'], {});
      rb.query('SearchDetails.Referrer.BaseData.ContactUserId', params['SearchDetails.Referrer.BaseData.ContactUserId'], {});
      rb.query('SearchDetails.Referrer.BaseData.LastStateChange', params['SearchDetails.Referrer.BaseData.LastStateChange'], {});
      rb.query('SearchDetails.Referrer.BaseData.LastStateReactivation', params['SearchDetails.Referrer.BaseData.LastStateReactivation'], {});
      rb.query('SearchDetails.Referrer.BaseData.TestScore', params['SearchDetails.Referrer.BaseData.TestScore'], {});
      rb.query('SearchDetails.Referrer.BaseData.EmailVerified', params['SearchDetails.Referrer.BaseData.EmailVerified'], {});
      rb.query('SearchDetails.Referrer.BaseData.PhoneNumberVerified', params['SearchDetails.Referrer.BaseData.PhoneNumberVerified'], {});
      rb.query('SearchDetails.Referrer.BaseData.PaymentSchedule.Id', params['SearchDetails.Referrer.BaseData.PaymentSchedule.Id'], {});
      rb.query('SearchDetails.Referrer.BaseData.PaymentSchedule.Name', params['SearchDetails.Referrer.BaseData.PaymentSchedule.Name'], {});
      rb.query('SearchDetails.Referrer.BaseData.PaymentSchedule.Active', params['SearchDetails.Referrer.BaseData.PaymentSchedule.Active'], {});
      rb.query('SearchDetails.Referrer.BaseData.WageCodeUser', params['SearchDetails.Referrer.BaseData.WageCodeUser'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthFullName', params['SearchDetails.Referrer.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthSurname', params['SearchDetails.Referrer.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthFirstName', params['SearchDetails.Referrer.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthFirstName2', params['SearchDetails.Referrer.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthCountry', params['SearchDetails.Referrer.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthPlace', params['SearchDetails.Referrer.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.Referrer.BirthData.BirthDate', params['SearchDetails.Referrer.BirthData.BirthDate'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersFullName', params['SearchDetails.Referrer.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersSurName', params['SearchDetails.Referrer.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersFirstName', params['SearchDetails.Referrer.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.Referrer.BirthData.MothersFirstName2', params['SearchDetails.Referrer.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.Referrer.AddressData.Country', params['SearchDetails.Referrer.AddressData.Country'], {});
      rb.query('SearchDetails.Referrer.AddressData.Postcode', params['SearchDetails.Referrer.AddressData.Postcode'], {});
      rb.query('SearchDetails.Referrer.AddressData.City', params['SearchDetails.Referrer.AddressData.City'], {});
      rb.query('SearchDetails.Referrer.AddressData.Address', params['SearchDetails.Referrer.AddressData.Address'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempCountry', params['SearchDetails.Referrer.AddressData.TempCountry'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempPostcode', params['SearchDetails.Referrer.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempCity', params['SearchDetails.Referrer.AddressData.TempCity'], {});
      rb.query('SearchDetails.Referrer.AddressData.TempAddress', params['SearchDetails.Referrer.AddressData.TempAddress'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.SchoolType.Id', params['SearchDetails.Referrer.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.SchoolType.Name', params['SearchDetails.Referrer.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.SchoolType.Active', params['SearchDetails.Referrer.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DrivingLicenses', params['SearchDetails.Referrer.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.Shifts', params['SearchDetails.Referrer.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.ShoeSize', params['SearchDetails.Referrer.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DressSize.Id', params['SearchDetails.Referrer.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DressSize.Name', params['SearchDetails.Referrer.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.DressSize.Active', params['SearchDetails.Referrer.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.OwnCar', params['SearchDetails.Referrer.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.OwnShoe', params['SearchDetails.Referrer.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.HealthRecordDate', params['SearchDetails.Referrer.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.LungScreeningDate', params['SearchDetails.Referrer.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.Description', params['SearchDetails.Referrer.ExtendedData.Description'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.GDPRAccepted', params['SearchDetails.Referrer.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.Labels', params['SearchDetails.Referrer.ExtendedData.Labels'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.LungScreeningPicture', params['SearchDetails.Referrer.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.HealthRecordPicture', params['SearchDetails.Referrer.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.ForbiddenLabels', params['SearchDetails.Referrer.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.Referrer.LocalizedState', params['SearchDetails.Referrer.LocalizedState'], {});
      rb.query('SearchDetails.Referrer.RegistrationLink', params['SearchDetails.Referrer.RegistrationLink'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextState', params['SearchDetails.Referrer.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateLocalized', params['SearchDetails.Referrer.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible', params['SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.Referrer.NextStateProperties.NextStateVisible', params['SearchDetails.Referrer.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.Referrer.RegistrationLinkSent', params['SearchDetails.Referrer.RegistrationLinkSent'], {});
      rb.query('SearchDetails.Referrer.ProfilePictureExists', params['SearchDetails.Referrer.ProfilePictureExists'], {});
      rb.query('SearchDetails.Referrer.PrivateDescription', params['SearchDetails.Referrer.PrivateDescription'], {});
      rb.query('SearchDetails.Referrer.StateBeforeInactivation', params['SearchDetails.Referrer.StateBeforeInactivation'], {});
      rb.query('SearchDetails.Referrer.NextStateDate', params['SearchDetails.Referrer.NextStateDate'], {});
      rb.query('SearchDetails.Relationship', params['SearchDetails.Relationship'], {});
      rb.query('SearchDetails.UserState', params['SearchDetails.UserState'], {});
      rb.query('SearchDetails.ContactUser.Id', params['SearchDetails.ContactUser.Id'], {});
      rb.query('SearchDetails.ContactUser.State', params['SearchDetails.ContactUser.State'], {});
      rb.query('SearchDetails.ContactUser.Password', params['SearchDetails.ContactUser.Password'], {});
      rb.query('SearchDetails.ContactUser.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.RoleName', params['SearchDetails.ContactUser.RoleName'], {});
      rb.query('SearchDetails.ContactUser.LocalizedRoleName', params['SearchDetails.ContactUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.Role.Id', params['SearchDetails.ContactUser.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.Role.RoleName', params['SearchDetails.ContactUser.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.Role.Description', params['SearchDetails.ContactUser.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.Role.Order', params['SearchDetails.ContactUser.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.Role.LocalizedRoleName', params['SearchDetails.ContactUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.FullName', params['SearchDetails.ContactUser.BaseData.FullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.MCode', params['SearchDetails.ContactUser.BaseData.MCode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Surname', params['SearchDetails.ContactUser.BaseData.Surname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Firstname', params['SearchDetails.ContactUser.BaseData.Firstname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Firstname2', params['SearchDetails.ContactUser.BaseData.Firstname2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.TaxNumber', params['SearchDetails.ContactUser.BaseData.TaxNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PhoneNumber', params['SearchDetails.ContactUser.BaseData.PhoneNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode', params['SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Email', params['SearchDetails.ContactUser.BaseData.Email'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Gender', params['SearchDetails.ContactUser.BaseData.Gender'], {});
      rb.query('SearchDetails.ContactUser.BaseData.SocialSecurityNumber', params['SearchDetails.ContactUser.BaseData.SocialSecurityNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.BankAccount', params['SearchDetails.ContactUser.BaseData.BankAccount'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Nationality', params['SearchDetails.ContactUser.BaseData.Nationality'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDType.Id', params['SearchDetails.ContactUser.BaseData.IDType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDType.Name', params['SearchDetails.ContactUser.BaseData.IDType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDType.Active', params['SearchDetails.ContactUser.BaseData.IDType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.IDNumber', params['SearchDetails.ContactUser.BaseData.IDNumber'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Id', params['SearchDetails.ContactUser.BaseData.Employer.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Name', params['SearchDetails.ContactUser.BaseData.Employer.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Active', params['SearchDetails.ContactUser.BaseData.Employer.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.Email', params['SearchDetails.ContactUser.BaseData.Employer.Email'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships', params['SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerId', params['SearchDetails.ContactUser.BaseData.EmployerId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationship.Id', params['SearchDetails.ContactUser.BaseData.EmployerRelationship.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationship.Name', params['SearchDetails.ContactUser.BaseData.EmployerRelationship.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationship.Active', params['SearchDetails.ContactUser.BaseData.EmployerRelationship.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRelationshipId', params['SearchDetails.ContactUser.BaseData.EmployerRelationshipId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Id', params['SearchDetails.ContactUser.BaseData.Recruiter.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Name', params['SearchDetails.ContactUser.BaseData.Recruiter.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Active', params['SearchDetails.ContactUser.BaseData.Recruiter.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.Email', params['SearchDetails.ContactUser.BaseData.Recruiter.Email'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships', params['SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships'], {});
      rb.query('SearchDetails.ContactUser.BaseData.RecruiterId', params['SearchDetails.ContactUser.BaseData.RecruiterId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.AdvertisementId', params['SearchDetails.ContactUser.BaseData.AdvertisementId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Id', params['SearchDetails.ContactUser.BaseData.Referrer.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.State', params['SearchDetails.ContactUser.BaseData.Referrer.State'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Password', params['SearchDetails.ContactUser.BaseData.Referrer.Password'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.RoleName', params['SearchDetails.ContactUser.BaseData.Referrer.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Id', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName', params['SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Description', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.Order', params['SearchDetails.ContactUser.BaseData.Referrer.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BaseData', params['SearchDetails.ContactUser.BaseData.Referrer.BaseData'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.City', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress', params['SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.LocalizedState', params['SearchDetails.ContactUser.BaseData.Referrer.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink', params['SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent', params['SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists', params['SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription', params['SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation', params['SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.Referrer.NextStateDate', params['SearchDetails.ContactUser.BaseData.Referrer.NextStateDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ReferrerId', params['SearchDetails.ContactUser.BaseData.ReferrerId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmployerRegistrationTime', params['SearchDetails.ContactUser.BaseData.EmployerRegistrationTime'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ProfilePicture', params['SearchDetails.ContactUser.BaseData.ProfilePicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.LastActivity', params['SearchDetails.ContactUser.BaseData.LastActivity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.State', params['SearchDetails.ContactUser.BaseData.ContactUser.State'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Password', params['SearchDetails.ContactUser.BaseData.ContactUser.Password'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.RoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Description', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.Order', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.Order'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName', params['SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BaseData', params['SearchDetails.ContactUser.BaseData.ContactUser.BaseData'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress', params['SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState', params['SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink', params['SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent', params['SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists', params['SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription', params['SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation', params['SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate', params['SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate'], {});
      rb.query('SearchDetails.ContactUser.BaseData.ContactUserId', params['SearchDetails.ContactUser.BaseData.ContactUserId'], {});
      rb.query('SearchDetails.ContactUser.BaseData.LastStateChange', params['SearchDetails.ContactUser.BaseData.LastStateChange'], {});
      rb.query('SearchDetails.ContactUser.BaseData.LastStateReactivation', params['SearchDetails.ContactUser.BaseData.LastStateReactivation'], {});
      rb.query('SearchDetails.ContactUser.BaseData.TestScore', params['SearchDetails.ContactUser.BaseData.TestScore'], {});
      rb.query('SearchDetails.ContactUser.BaseData.EmailVerified', params['SearchDetails.ContactUser.BaseData.EmailVerified'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PhoneNumberVerified', params['SearchDetails.ContactUser.BaseData.PhoneNumberVerified'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PaymentSchedule.Id', params['SearchDetails.ContactUser.BaseData.PaymentSchedule.Id'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PaymentSchedule.Name', params['SearchDetails.ContactUser.BaseData.PaymentSchedule.Name'], {});
      rb.query('SearchDetails.ContactUser.BaseData.PaymentSchedule.Active', params['SearchDetails.ContactUser.BaseData.PaymentSchedule.Active'], {});
      rb.query('SearchDetails.ContactUser.BaseData.WageCodeUser', params['SearchDetails.ContactUser.BaseData.WageCodeUser'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthFullName', params['SearchDetails.ContactUser.BirthData.BirthFullName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthSurname', params['SearchDetails.ContactUser.BirthData.BirthSurname'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthFirstName', params['SearchDetails.ContactUser.BirthData.BirthFirstName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthFirstName2', params['SearchDetails.ContactUser.BirthData.BirthFirstName2'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthCountry', params['SearchDetails.ContactUser.BirthData.BirthCountry'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthPlace', params['SearchDetails.ContactUser.BirthData.BirthPlace'], {});
      rb.query('SearchDetails.ContactUser.BirthData.BirthDate', params['SearchDetails.ContactUser.BirthData.BirthDate'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersFullName', params['SearchDetails.ContactUser.BirthData.MothersFullName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersSurName', params['SearchDetails.ContactUser.BirthData.MothersSurName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersFirstName', params['SearchDetails.ContactUser.BirthData.MothersFirstName'], {});
      rb.query('SearchDetails.ContactUser.BirthData.MothersFirstName2', params['SearchDetails.ContactUser.BirthData.MothersFirstName2'], {});
      rb.query('SearchDetails.ContactUser.AddressData.Country', params['SearchDetails.ContactUser.AddressData.Country'], {});
      rb.query('SearchDetails.ContactUser.AddressData.Postcode', params['SearchDetails.ContactUser.AddressData.Postcode'], {});
      rb.query('SearchDetails.ContactUser.AddressData.City', params['SearchDetails.ContactUser.AddressData.City'], {});
      rb.query('SearchDetails.ContactUser.AddressData.Address', params['SearchDetails.ContactUser.AddressData.Address'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempCountry', params['SearchDetails.ContactUser.AddressData.TempCountry'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempPostcode', params['SearchDetails.ContactUser.AddressData.TempPostcode'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempCity', params['SearchDetails.ContactUser.AddressData.TempCity'], {});
      rb.query('SearchDetails.ContactUser.AddressData.TempAddress', params['SearchDetails.ContactUser.AddressData.TempAddress'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.SchoolType.Id', params['SearchDetails.ContactUser.ExtendedData.SchoolType.Id'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.SchoolType.Name', params['SearchDetails.ContactUser.ExtendedData.SchoolType.Name'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.SchoolType.Active', params['SearchDetails.ContactUser.ExtendedData.SchoolType.Active'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DrivingLicenses', params['SearchDetails.ContactUser.ExtendedData.DrivingLicenses'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.Shifts', params['SearchDetails.ContactUser.ExtendedData.Shifts'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.ShoeSize', params['SearchDetails.ContactUser.ExtendedData.ShoeSize'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DressSize.Id', params['SearchDetails.ContactUser.ExtendedData.DressSize.Id'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DressSize.Name', params['SearchDetails.ContactUser.ExtendedData.DressSize.Name'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.DressSize.Active', params['SearchDetails.ContactUser.ExtendedData.DressSize.Active'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.OwnCar', params['SearchDetails.ContactUser.ExtendedData.OwnCar'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.OwnShoe', params['SearchDetails.ContactUser.ExtendedData.OwnShoe'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.HealthRecordDate', params['SearchDetails.ContactUser.ExtendedData.HealthRecordDate'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.LungScreeningDate', params['SearchDetails.ContactUser.ExtendedData.LungScreeningDate'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.Description', params['SearchDetails.ContactUser.ExtendedData.Description'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.GDPRAccepted', params['SearchDetails.ContactUser.ExtendedData.GDPRAccepted'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.Labels', params['SearchDetails.ContactUser.ExtendedData.Labels'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.LungScreeningPicture', params['SearchDetails.ContactUser.ExtendedData.LungScreeningPicture'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.HealthRecordPicture', params['SearchDetails.ContactUser.ExtendedData.HealthRecordPicture'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.ForbiddenLabels', params['SearchDetails.ContactUser.ExtendedData.ForbiddenLabels'], {});
      rb.query('SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted', params['SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'], {});
      rb.query('SearchDetails.ContactUser.LocalizedState', params['SearchDetails.ContactUser.LocalizedState'], {});
      rb.query('SearchDetails.ContactUser.RegistrationLink', params['SearchDetails.ContactUser.RegistrationLink'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextState', params['SearchDetails.ContactUser.NextStateProperties.NextState'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateLocalized', params['SearchDetails.ContactUser.NextStateProperties.NextStateLocalized'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized', params['SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible', params['SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible'], {});
      rb.query('SearchDetails.ContactUser.NextStateProperties.NextStateVisible', params['SearchDetails.ContactUser.NextStateProperties.NextStateVisible'], {});
      rb.query('SearchDetails.ContactUser.RegistrationLinkSent', params['SearchDetails.ContactUser.RegistrationLinkSent'], {});
      rb.query('SearchDetails.ContactUser.ProfilePictureExists', params['SearchDetails.ContactUser.ProfilePictureExists'], {});
      rb.query('SearchDetails.ContactUser.PrivateDescription', params['SearchDetails.ContactUser.PrivateDescription'], {});
      rb.query('SearchDetails.ContactUser.StateBeforeInactivation', params['SearchDetails.ContactUser.StateBeforeInactivation'], {});
      rb.query('SearchDetails.ContactUser.NextStateDate', params['SearchDetails.ContactUser.NextStateDate'], {});
      rb.query('SearchDetails.FullNameOrMcode', params['SearchDetails.FullNameOrMcode'], {});
      rb.query('SearchDetails.SchoolType', params['SearchDetails.SchoolType'], {});
      rb.query('SearchDetails.Shifts', params['SearchDetails.Shifts'], {});
      rb.query('SearchDetails.PrivateDescription', params['SearchDetails.PrivateDescription'], {});
      rb.query('SearchDetails.Role', params['SearchDetails.Role'], {});
      rb.query('ExcludeUserById', params.ExcludeUserById, {});
      rb.query('RequestUserId', params.RequestUserId, {});
      rb.query('GetCSV', params.GetCSV, {});
      rb.query('APIKey', params.APIKey, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Felhasználói azonosítók exportálása csv fájlba.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUserDataCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userUserDataCsv(params?: {
    ActiveOnly?: boolean;
    IncludeBuiltIn?: boolean;
    SearchTerm?: string;
    InRoles?: Array<number>;
    TranslationLanguageCode?: string;
    'SearchDetails.AdvertId'?: string;
    'SearchDetails.AgeMin'?: number;
    'SearchDetails.AgeMax'?: number;
    'SearchDetails.Recruiter'?: Array<EmployerData>;
    'SearchDetails.DateAfterInactive'?: string;
    'SearchDetails.Description'?: string;
    'SearchDetails.DrivingLicense'?: Array<DrivingLicenseData>;
    'SearchDetails.Employer'?: Array<EmployerData>;
    'SearchDetails.GenderFemale'?: boolean;
    'SearchDetails.GenderMale'?: boolean;
    'SearchDetails.Label'?: Array<LabelData>;
    'SearchDetails.AssignedLabel'?: boolean;
    'SearchDetails.LungFilter'?: string;
    'SearchDetails.OwnCar'?: boolean;
    'SearchDetails.OwnShoes'?: boolean;
    'SearchDetails.Referrer.Id'?: number;
    'SearchDetails.Referrer.State'?: UserState;
    'SearchDetails.Referrer.Password'?: string;
    'SearchDetails.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.Referrer.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.Referrer.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.Referrer.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.Referrer.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.Referrer.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.Referrer.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.Referrer.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.Referrer.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.Referrer.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.Referrer.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.Referrer.BaseData.Nationality'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Id'?: number;
    'SearchDetails.Referrer.BaseData.IDType.Name'?: string;
    'SearchDetails.Referrer.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.Referrer.BaseData.IDNumber'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Employer.Name'?: string;
    'SearchDetails.Referrer.BaseData.Employer.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Employer.Email'?: string;
    'SearchDetails.Referrer.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.EmployerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Id'?: number;
    'SearchDetails.Referrer.BaseData.Recruiter.Name'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Recruiter.Email'?: string;
    'SearchDetails.Referrer.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.Referrer.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.Referrer.BaseData.AdvertisementId'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.State'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.Password'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ReferrerId'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.Referrer.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.Referrer.BaseData.ProfilePicture'?: string;
    'SearchDetails.Referrer.BaseData.LastActivity'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.Password'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.Referrer.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.Referrer.BaseData.ContactUserId'?: number;
    'SearchDetails.Referrer.BaseData.LastStateChange'?: string;
    'SearchDetails.Referrer.BaseData.LastStateReactivation'?: string;
    'SearchDetails.Referrer.BaseData.TestScore'?: number;
    'SearchDetails.Referrer.BaseData.EmailVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.Referrer.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.Referrer.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.Referrer.LocalizedState'?: string;
    'SearchDetails.Referrer.RegistrationLink'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.Referrer.PrivateDescription'?: string;
    'SearchDetails.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.Referrer.NextStateDate'?: string;
    'SearchDetails.Relationship'?: Array<EmployerRelationshipData>;
    'SearchDetails.UserState'?: Array<UserStateData>;
    'SearchDetails.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.Role.LocalizedRoleName'?: string;

    /**
     * Teljes név
     */
    'SearchDetails.ContactUser.BaseData.FullName'?: string;

    /**
     * M kód
     */
    'SearchDetails.ContactUser.BaseData.MCode'?: string;

    /**
     * Vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Surname'?: string;

    /**
     * Utónév
     */
    'SearchDetails.ContactUser.BaseData.Firstname'?: string;

    /**
     * Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Firstname2'?: string;

    /**
     * Adóazonosító jel
     */
    'SearchDetails.ContactUser.BaseData.TaxNumber'?: string;

    /**
     * Telefonszám
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumber'?: string;

    /**
     * Telefonszám ország hívókód
     */
    'SearchDetails.ContactUser.BaseData.PhoneNumberCountryCode'?: string;

    /**
     * Email cím
     */
    'SearchDetails.ContactUser.BaseData.Email'?: string;

    /**
     * Nem
     */
    'SearchDetails.ContactUser.BaseData.Gender'?: boolean;

    /**
     * TAJ szám
     */
    'SearchDetails.ContactUser.BaseData.SocialSecurityNumber'?: string;

    /**
     * Bankszámla szám
     */
    'SearchDetails.ContactUser.BaseData.BankAccount'?: string;

    /**
     * Állampolgárság
     */
    'SearchDetails.ContactUser.BaseData.Nationality'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.IDType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.IDType.Active'?: boolean;

    /**
     * Okmány száma
     */
    'SearchDetails.ContactUser.BaseData.IDNumber'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Employer.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Employer.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Employer.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.EmployerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRelationship.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRelationshipId'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Recruiter.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Recruiter.Email'?: string;
    'SearchDetails.ContactUser.BaseData.Recruiter.EmployerRelationships'?: Array<EmployerRelationshipData>;
    'SearchDetails.ContactUser.BaseData.RecruiterId'?: number;

    /**
     * Hirdetés azonosító
     */
    'SearchDetails.ContactUser.BaseData.AdvertisementId'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.Password'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.Referrer.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.Referrer.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.Referrer.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.Referrer.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.Referrer.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ReferrerId'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationUserId'?: number;

    /**
     * Munkáltatói beléptetés időpontja
     */
    'SearchDetails.ContactUser.BaseData.EmployerRegistrationTime'?: string;
    'SearchDetails.ContactUser.BaseData.ProfilePicture'?: string;
    'SearchDetails.ContactUser.BaseData.LastActivity'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.State'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.Password'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Permissions.GlobalPermissions'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.RoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.Order'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.Role.LocalizedRoleName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BaseData'?: UserDataBase;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.BaseData.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.BaseData.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.BaseData.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.BaseData.ContactUser.NextStateDate'?: string;
    'SearchDetails.ContactUser.BaseData.ContactUserId'?: number;
    'SearchDetails.ContactUser.BaseData.LastStateChange'?: string;
    'SearchDetails.ContactUser.BaseData.LastStateReactivation'?: string;
    'SearchDetails.ContactUser.BaseData.TestScore'?: number;
    'SearchDetails.ContactUser.BaseData.EmailVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PhoneNumberVerified'?: boolean;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Id'?: number;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Name'?: string;
    'SearchDetails.ContactUser.BaseData.PaymentSchedule.Active'?: boolean;

    /**
     * Bérkód
     */
    'SearchDetails.ContactUser.BaseData.WageCodeUser'?: string;

    /**
     * Születési teljes név
     */
    'SearchDetails.ContactUser.BirthData.BirthFullName'?: string;

    /**
     * Születési vezetéknév
     */
    'SearchDetails.ContactUser.BirthData.BirthSurname'?: string;

    /**
     * Születési utónév
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName'?: string;

    /**
     * Születési Utónév 2
     */
    'SearchDetails.ContactUser.BirthData.BirthFirstName2'?: string;
    'SearchDetails.ContactUser.BirthData.BirthCountry'?: string;
    'SearchDetails.ContactUser.BirthData.BirthPlace'?: string;
    'SearchDetails.ContactUser.BirthData.BirthDate'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFullName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersSurName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName'?: string;
    'SearchDetails.ContactUser.BirthData.MothersFirstName2'?: string;

    /**
     * Lakcím ország
     */
    'SearchDetails.ContactUser.AddressData.Country'?: string;

    /**
     * Lakcím irsz
     */
    'SearchDetails.ContactUser.AddressData.Postcode'?: string;

    /**
     * Lakcím település
     */
    'SearchDetails.ContactUser.AddressData.City'?: string;

    /**
     * Lakcím cím
     */
    'SearchDetails.ContactUser.AddressData.Address'?: string;

    /**
     * Ideiglenes cím ország
     */
    'SearchDetails.ContactUser.AddressData.TempCountry'?: string;

    /**
     * Ideiglenes cím irsz
     */
    'SearchDetails.ContactUser.AddressData.TempPostcode'?: string;

    /**
     * Ideiglenes cím település
     */
    'SearchDetails.ContactUser.AddressData.TempCity'?: string;

    /**
     * Ideiglenes cím
     */
    'SearchDetails.ContactUser.AddressData.TempAddress'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.SchoolType.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.DrivingLicenses'?: Array<DrivingLicenseData>;
    'SearchDetails.ContactUser.ExtendedData.Shifts'?: Array<ShiftData>;
    'SearchDetails.ContactUser.ExtendedData.ShoeSize'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Id'?: number;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Name'?: string;
    'SearchDetails.ContactUser.ExtendedData.DressSize.Active'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnCar'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.OwnShoe'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningDate'?: string;
    'SearchDetails.ContactUser.ExtendedData.Description'?: string;
    'SearchDetails.ContactUser.ExtendedData.GDPRAccepted'?: boolean;
    'SearchDetails.ContactUser.ExtendedData.Labels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.LungScreeningPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.HealthRecordPicture'?: string;
    'SearchDetails.ContactUser.ExtendedData.ForbiddenLabels'?: Array<LabelData>;
    'SearchDetails.ContactUser.ExtendedData.GeneralEmployeeInformationAccepted'?: boolean;
    'SearchDetails.ContactUser.LocalizedState'?: string;
    'SearchDetails.ContactUser.RegistrationLink'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextState'?: UserState;
    'SearchDetails.ContactUser.NextStateProperties.NextStateLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonTextLocalized'?: string;
    'SearchDetails.ContactUser.NextStateProperties.NextStateButtonVisible'?: boolean;
    'SearchDetails.ContactUser.NextStateProperties.NextStateVisible'?: boolean;
    'SearchDetails.ContactUser.RegistrationLinkSent'?: boolean;
    'SearchDetails.ContactUser.ProfilePictureExists'?: boolean;
    'SearchDetails.ContactUser.PrivateDescription'?: string;
    'SearchDetails.ContactUser.StateBeforeInactivation'?: UserState;
    'SearchDetails.ContactUser.NextStateDate'?: string;
    'SearchDetails.FullNameOrMcode'?: string;
    'SearchDetails.SchoolType'?: Array<SchoolTypeData>;
    'SearchDetails.Shifts'?: Array<ShiftData>;
    'SearchDetails.PrivateDescription'?: string;
    'SearchDetails.Role'?: Array<RoleData>;
    ExcludeUserById?: number;
    RequestUserId?: number;
    GetCSV?: boolean;
    APIKey?: string;
  },
  context?: HttpContext

): Observable<Blob> {

    return this.userUserDataCsv$Response(params,context).pipe(
      map((r: StrictHttpResponse<Blob>) => r.body as Blob)
    );
  }

}
