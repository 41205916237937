/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ContactRecipientData } from '../models/contact-recipient-data';
import { DressSizeData } from '../models/dress-size-data';
import { DrivingLicenseData } from '../models/driving-license-data';
import { EmailTypeData } from '../models/email-type-data';
import { EventUserPresenceStateData } from '../models/event-user-presence-state-data';
import { EventUserStateData } from '../models/event-user-state-data';
import { IdTypeData } from '../models/id-type-data';
import { NotificationChannelTypeData } from '../models/notification-channel-type-data';
import { NotificationSendingStateData } from '../models/notification-sending-state-data';
import { NotificationSourceTypeData } from '../models/notification-source-type-data';
import { NotificationSubstitutesTypeData } from '../models/notification-substitutes-type-data';
import { PaymentScheduleData } from '../models/payment-schedule-data';
import { PaymentTypeData } from '../models/payment-type-data';
import { ReportStateData } from '../models/report-state-data';
import { RequiredUserFieldsData } from '../models/required-user-fields-data';
import { SchoolTypeData } from '../models/school-type-data';
import { SearchPeriodData } from '../models/search-period-data';
import { ShiftData } from '../models/shift-data';
import { UserStateData } from '../models/user-state-data';

@Injectable({
  providedIn: 'root',
})
export class EnumHelpersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation enumHelpersDressSizes
   */
  static readonly EnumHelpersDressSizesPath = '/api/EnumHelpers/DressSizes';

  /**
   * Ruhaméretek lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersDressSizes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDressSizes$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DressSizeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersDressSizesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DressSizeData>>;
      })
    );
  }

  /**
   * Ruhaméretek lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersDressSizes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDressSizes$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<DressSizeData>> {

    return this.enumHelpersDressSizes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DressSizeData>>) => r.body as Array<DressSizeData>)
    );
  }

  /**
   * Ruhaméretek lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersDressSizes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDressSizes$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DressSizeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersDressSizesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DressSizeData>>;
      })
    );
  }

  /**
   * Ruhaméretek lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersDressSizes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDressSizes(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<DressSizeData>> {

    return this.enumHelpersDressSizes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DressSizeData>>) => r.body as Array<DressSizeData>)
    );
  }

  /**
   * Path part for operation enumHelpersDrivingLicenses
   */
  static readonly EnumHelpersDrivingLicensesPath = '/api/EnumHelpers/DrivingLicenses';

  /**
   * Jogosítvány típusok lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersDrivingLicenses$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDrivingLicenses$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DrivingLicenseData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersDrivingLicensesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DrivingLicenseData>>;
      })
    );
  }

  /**
   * Jogosítvány típusok lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersDrivingLicenses$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDrivingLicenses$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<DrivingLicenseData>> {

    return this.enumHelpersDrivingLicenses$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DrivingLicenseData>>) => r.body as Array<DrivingLicenseData>)
    );
  }

  /**
   * Jogosítvány típusok lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersDrivingLicenses()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDrivingLicenses$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DrivingLicenseData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersDrivingLicensesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DrivingLicenseData>>;
      })
    );
  }

  /**
   * Jogosítvány típusok lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersDrivingLicenses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersDrivingLicenses(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<DrivingLicenseData>> {

    return this.enumHelpersDrivingLicenses$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DrivingLicenseData>>) => r.body as Array<DrivingLicenseData>)
    );
  }

  /**
   * Path part for operation enumHelpersSchoolTypes
   */
  static readonly EnumHelpersSchoolTypesPath = '/api/EnumHelpers/SchoolTypes';

  /**
   * Iskolai végzettségek lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersSchoolTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSchoolTypes$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SchoolTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersSchoolTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SchoolTypeData>>;
      })
    );
  }

  /**
   * Iskolai végzettségek lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersSchoolTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSchoolTypes$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<SchoolTypeData>> {

    return this.enumHelpersSchoolTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SchoolTypeData>>) => r.body as Array<SchoolTypeData>)
    );
  }

  /**
   * Iskolai végzettségek lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersSchoolTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSchoolTypes$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SchoolTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersSchoolTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SchoolTypeData>>;
      })
    );
  }

  /**
   * Iskolai végzettségek lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersSchoolTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSchoolTypes(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<SchoolTypeData>> {

    return this.enumHelpersSchoolTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SchoolTypeData>>) => r.body as Array<SchoolTypeData>)
    );
  }

  /**
   * Path part for operation enumHelpersShifts
   */
  static readonly EnumHelpersShiftsPath = '/api/EnumHelpers/Shifts';

  /**
   * Műszakok lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersShifts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersShifts$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ShiftData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersShiftsPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShiftData>>;
      })
    );
  }

  /**
   * Műszakok lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersShifts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersShifts$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<ShiftData>> {

    return this.enumHelpersShifts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ShiftData>>) => r.body as Array<ShiftData>)
    );
  }

  /**
   * Műszakok lekérdezése.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersShifts()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersShifts$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ShiftData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersShiftsPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ShiftData>>;
      })
    );
  }

  /**
   * Műszakok lekérdezése.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersShifts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersShifts(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<ShiftData>> {

    return this.enumHelpersShifts$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ShiftData>>) => r.body as Array<ShiftData>)
    );
  }

  /**
   * Path part for operation enumHelpersUserStates
   */
  static readonly EnumHelpersUserStatesPath = '/api/EnumHelpers/UserStates';

  /**
   * Felhasználói státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersUserStates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersUserStates$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersUserStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserStateData>>;
      })
    );
  }

  /**
   * Felhasználói státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersUserStates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersUserStates$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<UserStateData>> {

    return this.enumHelpersUserStates$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserStateData>>) => r.body as Array<UserStateData>)
    );
  }

  /**
   * Felhasználói státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersUserStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersUserStates$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<UserStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersUserStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserStateData>>;
      })
    );
  }

  /**
   * Felhasználói státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersUserStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersUserStates(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<UserStateData>> {

    return this.enumHelpersUserStates$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<UserStateData>>) => r.body as Array<UserStateData>)
    );
  }

  /**
   * Path part for operation enumHelpersIdTypes
   */
  static readonly EnumHelpersIdTypesPath = '/api/EnumHelpers/IDTypes';

  /**
   * Okmány azonosítók.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersIdTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersIdTypes$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<IdTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersIdTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IdTypeData>>;
      })
    );
  }

  /**
   * Okmány azonosítók.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersIdTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersIdTypes$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<IdTypeData>> {

    return this.enumHelpersIdTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<IdTypeData>>) => r.body as Array<IdTypeData>)
    );
  }

  /**
   * Okmány azonosítók.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersIdTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersIdTypes$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<IdTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersIdTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IdTypeData>>;
      })
    );
  }

  /**
   * Okmány azonosítók.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersIdTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersIdTypes(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<IdTypeData>> {

    return this.enumHelpersIdTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<IdTypeData>>) => r.body as Array<IdTypeData>)
    );
  }

  /**
   * Path part for operation enumHelpersEmailTypes
   */
  static readonly EnumHelpersEmailTypesPath = '/api/EnumHelpers/EmailTypes';

  /**
   * Email típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersEmailTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEmailTypes$Plain$Response(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EmailTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersEmailTypesPath, 'get');
    if (params) {
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailTypeData>>;
      })
    );
  }

  /**
   * Email típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersEmailTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEmailTypes$Plain(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<EmailTypeData>> {

    return this.enumHelpersEmailTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EmailTypeData>>) => r.body as Array<EmailTypeData>)
    );
  }

  /**
   * Email típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersEmailTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEmailTypes$Response(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EmailTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersEmailTypesPath, 'get');
    if (params) {
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EmailTypeData>>;
      })
    );
  }

  /**
   * Email típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersEmailTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEmailTypes(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<EmailTypeData>> {

    return this.enumHelpersEmailTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EmailTypeData>>) => r.body as Array<EmailTypeData>)
    );
  }

  /**
   * Path part for operation enumHelpersRequiredUserFields
   */
  static readonly EnumHelpersRequiredUserFieldsPath = '/api/EnumHelpers/RequiredUserFields';

  /**
   * Kötelező mezők felhasználói státuszok alapján.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersRequiredUserFields$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersRequiredUserFields$Plain$Response(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RequiredUserFieldsData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersRequiredUserFieldsPath, 'get');
    if (params) {
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequiredUserFieldsData>>;
      })
    );
  }

  /**
   * Kötelező mezők felhasználói státuszok alapján.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersRequiredUserFields$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersRequiredUserFields$Plain(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<RequiredUserFieldsData>> {

    return this.enumHelpersRequiredUserFields$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RequiredUserFieldsData>>) => r.body as Array<RequiredUserFieldsData>)
    );
  }

  /**
   * Kötelező mezők felhasználói státuszok alapján.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersRequiredUserFields()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersRequiredUserFields$Response(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RequiredUserFieldsData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersRequiredUserFieldsPath, 'get');
    if (params) {
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequiredUserFieldsData>>;
      })
    );
  }

  /**
   * Kötelező mezők felhasználói státuszok alapján.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersRequiredUserFields$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersRequiredUserFields(params?: {
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<RequiredUserFieldsData>> {

    return this.enumHelpersRequiredUserFields$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RequiredUserFieldsData>>) => r.body as Array<RequiredUserFieldsData>)
    );
  }

  /**
   * Path part for operation enumHelpersContactRecipients
   */
  static readonly EnumHelpersContactRecipientsPath = '/api/EnumHelpers/ContactRecipients';

  /**
   * Kapcsolat címzettek.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersContactRecipients$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersContactRecipients$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ContactRecipientData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersContactRecipientsPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactRecipientData>>;
      })
    );
  }

  /**
   * Kapcsolat címzettek.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersContactRecipients$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersContactRecipients$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<ContactRecipientData>> {

    return this.enumHelpersContactRecipients$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ContactRecipientData>>) => r.body as Array<ContactRecipientData>)
    );
  }

  /**
   * Kapcsolat címzettek.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersContactRecipients()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersContactRecipients$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ContactRecipientData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersContactRecipientsPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactRecipientData>>;
      })
    );
  }

  /**
   * Kapcsolat címzettek.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersContactRecipients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersContactRecipients(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<ContactRecipientData>> {

    return this.enumHelpersContactRecipients$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ContactRecipientData>>) => r.body as Array<ContactRecipientData>)
    );
  }

  /**
   * Path part for operation enumHelpersEventUserStates
   */
  static readonly EnumHelpersEventUserStatesPath = '/api/EnumHelpers/EventUserStates';

  /**
   * Esemény felhasználói státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersEventUserStates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserStates$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventUserStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersEventUserStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventUserStateData>>;
      })
    );
  }

  /**
   * Esemény felhasználói státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersEventUserStates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserStates$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<EventUserStateData>> {

    return this.enumHelpersEventUserStates$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventUserStateData>>) => r.body as Array<EventUserStateData>)
    );
  }

  /**
   * Esemény felhasználói státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersEventUserStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserStates$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventUserStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersEventUserStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventUserStateData>>;
      })
    );
  }

  /**
   * Esemény felhasználói státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersEventUserStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserStates(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<EventUserStateData>> {

    return this.enumHelpersEventUserStates$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventUserStateData>>) => r.body as Array<EventUserStateData>)
    );
  }

  /**
   * Path part for operation enumHelpersEventUserPresenceStates
   */
  static readonly EnumHelpersEventUserPresenceStatesPath = '/api/EnumHelpers/EventUserPresenceStates';

  /**
   * Esemény felhasználói jelenlét státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersEventUserPresenceStates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserPresenceStates$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventUserPresenceStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersEventUserPresenceStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventUserPresenceStateData>>;
      })
    );
  }

  /**
   * Esemény felhasználói jelenlét státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersEventUserPresenceStates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserPresenceStates$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<EventUserPresenceStateData>> {

    return this.enumHelpersEventUserPresenceStates$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventUserPresenceStateData>>) => r.body as Array<EventUserPresenceStateData>)
    );
  }

  /**
   * Esemény felhasználói jelenlét státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersEventUserPresenceStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserPresenceStates$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EventUserPresenceStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersEventUserPresenceStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EventUserPresenceStateData>>;
      })
    );
  }

  /**
   * Esemény felhasználói jelenlét státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersEventUserPresenceStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersEventUserPresenceStates(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<EventUserPresenceStateData>> {

    return this.enumHelpersEventUserPresenceStates$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EventUserPresenceStateData>>) => r.body as Array<EventUserPresenceStateData>)
    );
  }

  /**
   * Path part for operation enumHelpersReportStates
   */
  static readonly EnumHelpersReportStatesPath = '/api/EnumHelpers/ReportStates';

  /**
   * Lejelentés státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersReportStates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersReportStates$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReportStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersReportStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReportStateData>>;
      })
    );
  }

  /**
   * Lejelentés státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersReportStates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersReportStates$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<ReportStateData>> {

    return this.enumHelpersReportStates$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReportStateData>>) => r.body as Array<ReportStateData>)
    );
  }

  /**
   * Lejelentés státuszok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersReportStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersReportStates$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReportStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersReportStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReportStateData>>;
      })
    );
  }

  /**
   * Lejelentés státuszok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersReportStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersReportStates(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<ReportStateData>> {

    return this.enumHelpersReportStates$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReportStateData>>) => r.body as Array<ReportStateData>)
    );
  }

  /**
   * Path part for operation enumHelpersNotificationSourceTypes
   */
  static readonly EnumHelpersNotificationSourceTypesPath = '/api/EnumHelpers/NotificationSourceTypes';

  /**
   * Értesítés forrás típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationSourceTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSourceTypes$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationSourceTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationSourceTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationSourceTypeData>>;
      })
    );
  }

  /**
   * Értesítés forrás típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationSourceTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSourceTypes$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationSourceTypeData>> {

    return this.enumHelpersNotificationSourceTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationSourceTypeData>>) => r.body as Array<NotificationSourceTypeData>)
    );
  }

  /**
   * Értesítés forrás típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationSourceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSourceTypes$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationSourceTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationSourceTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationSourceTypeData>>;
      })
    );
  }

  /**
   * Értesítés forrás típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationSourceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSourceTypes(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationSourceTypeData>> {

    return this.enumHelpersNotificationSourceTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationSourceTypeData>>) => r.body as Array<NotificationSourceTypeData>)
    );
  }

  /**
   * Path part for operation enumHelpersNotificationChannelTypes
   */
  static readonly EnumHelpersNotificationChannelTypesPath = '/api/EnumHelpers/NotificationChannelTypes';

  /**
   * Értesítés csatorna típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationChannelTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationChannelTypes$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationChannelTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationChannelTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationChannelTypeData>>;
      })
    );
  }

  /**
   * Értesítés csatorna típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationChannelTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationChannelTypes$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationChannelTypeData>> {

    return this.enumHelpersNotificationChannelTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationChannelTypeData>>) => r.body as Array<NotificationChannelTypeData>)
    );
  }

  /**
   * Értesítés csatorna típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationChannelTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationChannelTypes$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationChannelTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationChannelTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationChannelTypeData>>;
      })
    );
  }

  /**
   * Értesítés csatorna típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationChannelTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationChannelTypes(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationChannelTypeData>> {

    return this.enumHelpersNotificationChannelTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationChannelTypeData>>) => r.body as Array<NotificationChannelTypeData>)
    );
  }

  /**
   * Path part for operation enumHelpersNotificationSubstitutesTypes
   */
  static readonly EnumHelpersNotificationSubstitutesTypesPath = '/api/EnumHelpers/NotificationSubstitutesTypes';

  /**
   * Értesítés helyettesítés típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationSubstitutesTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSubstitutesTypes$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationSubstitutesTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationSubstitutesTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationSubstitutesTypeData>>;
      })
    );
  }

  /**
   * Értesítés helyettesítés típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationSubstitutesTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSubstitutesTypes$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationSubstitutesTypeData>> {

    return this.enumHelpersNotificationSubstitutesTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationSubstitutesTypeData>>) => r.body as Array<NotificationSubstitutesTypeData>)
    );
  }

  /**
   * Értesítés helyettesítés típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationSubstitutesTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSubstitutesTypes$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationSubstitutesTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationSubstitutesTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationSubstitutesTypeData>>;
      })
    );
  }

  /**
   * Értesítés helyettesítés típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationSubstitutesTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSubstitutesTypes(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationSubstitutesTypeData>> {

    return this.enumHelpersNotificationSubstitutesTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationSubstitutesTypeData>>) => r.body as Array<NotificationSubstitutesTypeData>)
    );
  }

  /**
   * Path part for operation enumHelpersNotificationSendingStates
   */
  static readonly EnumHelpersNotificationSendingStatesPath = '/api/EnumHelpers/NotificationSendingStates';

  /**
   * Értesítés kézbesítési állapotok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationSendingStates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSendingStates$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationSendingStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationSendingStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationSendingStateData>>;
      })
    );
  }

  /**
   * Értesítés kézbesítési állapotok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationSendingStates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSendingStates$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationSendingStateData>> {

    return this.enumHelpersNotificationSendingStates$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationSendingStateData>>) => r.body as Array<NotificationSendingStateData>)
    );
  }

  /**
   * Értesítés kézbesítési állapotok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersNotificationSendingStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSendingStates$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NotificationSendingStateData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersNotificationSendingStatesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationSendingStateData>>;
      })
    );
  }

  /**
   * Értesítés kézbesítési állapotok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersNotificationSendingStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersNotificationSendingStates(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<NotificationSendingStateData>> {

    return this.enumHelpersNotificationSendingStates$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NotificationSendingStateData>>) => r.body as Array<NotificationSendingStateData>)
    );
  }

  /**
   * Path part for operation enumHelpersSearchPeriods
   */
  static readonly EnumHelpersSearchPeriodsPath = '/api/EnumHelpers/SearchPeriods';

  /**
   * Időszak szűrések.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersSearchPeriods$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSearchPeriods$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SearchPeriodData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersSearchPeriodsPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchPeriodData>>;
      })
    );
  }

  /**
   * Időszak szűrések.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersSearchPeriods$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSearchPeriods$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<SearchPeriodData>> {

    return this.enumHelpersSearchPeriods$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SearchPeriodData>>) => r.body as Array<SearchPeriodData>)
    );
  }

  /**
   * Időszak szűrések.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersSearchPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSearchPeriods$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SearchPeriodData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersSearchPeriodsPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SearchPeriodData>>;
      })
    );
  }

  /**
   * Időszak szűrések.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersSearchPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersSearchPeriods(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<SearchPeriodData>> {

    return this.enumHelpersSearchPeriods$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SearchPeriodData>>) => r.body as Array<SearchPeriodData>)
    );
  }

  /**
   * Path part for operation enumHelpersPaymentTypes
   */
  static readonly EnumHelpersPaymentTypesPath = '/api/EnumHelpers/PaymentTypes';

  /**
   * Fizetés típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersPaymentTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentTypes$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PaymentTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersPaymentTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentTypeData>>;
      })
    );
  }

  /**
   * Fizetés típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersPaymentTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentTypes$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<PaymentTypeData>> {

    return this.enumHelpersPaymentTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PaymentTypeData>>) => r.body as Array<PaymentTypeData>)
    );
  }

  /**
   * Fizetés típusok.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersPaymentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentTypes$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PaymentTypeData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersPaymentTypesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentTypeData>>;
      })
    );
  }

  /**
   * Fizetés típusok.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersPaymentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentTypes(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<PaymentTypeData>> {

    return this.enumHelpersPaymentTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PaymentTypeData>>) => r.body as Array<PaymentTypeData>)
    );
  }

  /**
   * Path part for operation enumHelpersPaymentSchedules
   */
  static readonly EnumHelpersPaymentSchedulesPath = '/api/EnumHelpers/PaymentSchedules';

  /**
   * Fizetési ütem.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersPaymentSchedules$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentSchedules$Plain$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PaymentScheduleData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersPaymentSchedulesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentScheduleData>>;
      })
    );
  }

  /**
   * Fizetési ütem.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersPaymentSchedules$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentSchedules$Plain(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<PaymentScheduleData>> {

    return this.enumHelpersPaymentSchedules$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PaymentScheduleData>>) => r.body as Array<PaymentScheduleData>)
    );
  }

  /**
   * Fizetési ütem.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enumHelpersPaymentSchedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentSchedules$Response(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<PaymentScheduleData>>> {

    const rb = new RequestBuilder(this.rootUrl, EnumHelpersService.EnumHelpersPaymentSchedulesPath, 'get');
    if (params) {
      rb.query('Search', params.Search, {});
      rb.query('OrderColumns', params.OrderColumns, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('MaxPageSize', params.MaxPageSize, {});
      rb.query('SkipTotalItemCount', params.SkipTotalItemCount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentScheduleData>>;
      })
    );
  }

  /**
   * Fizetési ütem.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `enumHelpersPaymentSchedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enumHelpersPaymentSchedules(params?: {
    Search?: string;
    OrderColumns?: Array<string>;
    PageIndex?: number;
    PageSize?: number;
    MaxPageSize?: number;
    SkipTotalItemCount?: boolean;
  },
  context?: HttpContext

): Observable<Array<PaymentScheduleData>> {

    return this.enumHelpersPaymentSchedules$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<PaymentScheduleData>>) => r.body as Array<PaymentScheduleData>)
    );
  }

}
